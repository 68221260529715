import React, { useEffect, useState } from "react";
import { Box, Grid, Modal, Paper, Typography } from "@material-ui/core";
import Remote from "../../httpService/Remote";
import ApiService from "../../httpService/ApiService";
import IApiResultData from "../../Models/IApiResultData";
import AppContainer from "../core/AppContainer";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../Utilities/Toast";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import AppButtonOutline from "../core/App-Button/App-Button-Outline";
import AppLabelTitle from "../core/Label/App-Label-Title";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import useStyles from "./RequestLoan.styles";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import Routing from "../../Routing/routing";
import AppButton from "../core/App-Button/App-Button";
import ResponseLoanRequestDto from "../../Models/AppModels/Loan/ResponseLoanRequestDto";
import AppLabelContent from "../core/Label/App-Label-Content";
import { NumberHelper } from "../../Utilities/NumberHelper";
import IApiResult from "../../Models/IApiResult";
import { TextField } from "@mui/material";

const RequestLoan: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dataLoan, setDataLoan] = useState<
    ResponseLoanRequestDto | undefined
  >();
  const [showRequestMaxLoan, setShowRequestMaxLoan] = useState<boolean>(false);
  const [showAcceptRequestLoan, setShowAcceptRequestLoan] =
    useState<boolean>(false);

  const [amount, setAmount] = useState<string>("0");
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  const [showAddReceiptModal, setShowAddReceiptModal] =
    useState<boolean>(false);

  useEffect(() => {
    GetUserReceivableLoanInfo();
  }, []);

  const GetUserReceivableLoanInfo = () => {
    Remote.call(
      ApiService.getUserReceivableLoanInfo(),
      (response: IApiResultData<ResponseLoanRequestDto>) => {
        setDataLoan(response.data);
        checkShowDataLoan(response.data);
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const checkShowDataLoan = (data: ResponseLoanRequestDto) => {
    setShowRequestMaxLoan(data.canRequestMaxLoan);
    setShowAcceptRequestLoan(data.canRequestNormalLoan);
  };

  const handleGoBack = () => {
    navigate(Routing.Main);
  };

  const onClickRequestMaxLoan = () => {
    setShowAddReceiptModal(true);
  };

  const handleCloseModal = () => {
    setShowAddReceiptModal(false);
  };

  const requestMaxLoan = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("amount", amount.replace(/,/g, ""));
    formData.append("description", description);
    formData.append("file", file ? file : "");

    Remote.call(
      ApiService.requestMaxLoan(formData),
      (response: IApiResultData<string>) => {
        Toast.show(response.message);
        if (response.isSuccess) {
          navigate(Routing.Main);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
      }
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setAmount(NumberHelper.separateComma(parseInt(numericValue)));
  };

  const onClickNext = () => {
    if (!showAcceptRequestLoan) {
      Toast.show("شما مجاز به درخواست وام نمی باشید");
      return;
    }
    if (!dataLoan) {
      return;
    } else {
      if (dataLoan.receivableLoanAmount < dataLoan.minLoanAmount) {
        Toast.show("مبلغ قابل درخواست کمتر از حد مجاز میباشد");
        return;
      }
      Remote.call(
        ApiService.createRequestLoanInitial(),
        (response: IApiResult) => {
          Toast.show(response.message);
          if (response.isSuccess) {
            navigate(Routing.GuarantorRequestLoan);
          }
        },
        (onProgress: boolean) => {
          setLoading(onProgress);
        },
        (error: string) => {
          Toast.show(error);
        }
      );
    }
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AppToolbar title={AppMessages.RequestLoan} onClickBack={handleGoBack} />

      <Box style={{ width: "100%" }} className={classes.container}>
        <Grid container className={GlobalStyle().main}>
          <AppGridItem all={12} style={{ paddingTop: "10px" }}>
            <AppLabelTitle
              className={classes.HeaderLoan}
              label={AppMessages.loanData}
            />
          </AppGridItem>

          {/**amount Loan Request*/}
          <AppGridItem all={6}>
            <AppLabelTitle
              style={{ textAlign: "left" }}
              label={`${AppMessages.amountLoanRequest} : `}
            />
          </AppGridItem>
          <AppGridItem all={6}>
            <AppLabelTitle
              style={{
                textAlign: "right",
                color: GlobalConstants.PrimaryColor,
              }}
              label={`${NumberHelper.get(dataLoan?.receivableLoanAmount)}`}
            />
          </AppGridItem>

          {/**amount max Request*/}
          <AppGridItem all={6}>
            <AppLabelTitle
              style={{ textAlign: "left" }}
              label={`${AppMessages.amountMaxLoanRequest} : `}
            />
          </AppGridItem>
          <AppGridItem all={6}>
            <AppLabelTitle
              style={{
                textAlign: "right",
                color: GlobalConstants.OnPrimaryColor,
              }}
              label={NumberHelper.get(dataLoan?.maxLoanAmount)}
            />
          </AppGridItem>

          {/**min Loan Amount*/}
          <AppGridItem all={6}>
            <AppLabelTitle
              style={{ textAlign: "left" }}
              label={`${AppMessages.amountMinLoanRequest} : `}
            />
          </AppGridItem>
          <AppGridItem all={6}>
            <AppLabelTitle
              style={{
                textAlign: "right",
                color: GlobalConstants.OnPrimaryColor,
              }}
              label={`${NumberHelper.get(dataLoan?.minLoanAmount)}`}
            />
          </AppGridItem>

          {/**difference Loan Amount*/}
          <AppGridItem hidden={!showRequestMaxLoan} all={6}>
            <AppLabelTitle
              style={{ textAlign: "left" }}
              label={`${AppMessages.differenceAmountMaxLoan} : `}
            />
          </AppGridItem>
          <AppGridItem hidden={!showRequestMaxLoan} all={6}>
            <AppLabelTitle
              style={{
                textAlign: "right",
                color: GlobalConstants.RedColor,
              }}
              label={NumberHelper.get(dataLoan?.maxLoanDifference)}
            />
          </AppGridItem>

          <AppGridItem
            hidden={!showRequestMaxLoan}
            all={12}
            style={{ textAlign: "center", paddingTop: "20px" }}
          >
            <AppButtonOutline
              label={AppMessages.requestMaxLoan}
              className={classes.btnOutline}
              onClick={onClickRequestMaxLoan}
            />
            <AppLabelContent
              style={{ textAlign: "center", margin: "20px" }}
              label={AppMessages.hintRequestMaxLoan}
            />
          </AppGridItem>

          <AppGridItem
            all={12}
            //hidden={!showAcceptRequestLoan}
            style={{
              bottom: 0,
              left: 0,
              right: 0,
              marginBottom: 20,
              position: "fixed",
              textAlign: "center",
              paddingTop: "50px",
            }}
          >
            <AppButton
              type="button"
              label={AppMessages.AcceptContinue}
              onClick={onClickNext}
            />
          </AppGridItem>
        </Grid>
      </Box>

      <Modal open={showAddReceiptModal} onClose={handleCloseModal}>
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            width: "fit-content",
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            ثبت رسید
          </Typography>
          <form onSubmit={requestMaxLoan}>
            <div>
              <TextField
                dir="ltr"
                label="مبلغ"
                value={amount}
                onChange={handleAmountChange}
                fullWidth
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <TextField
                label="توضیحات"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                fullWidth
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Typography variant="body1" gutterBottom>
                بارگذاری عکس رسید
              </Typography>
              <input type="file" onChange={handleFileChange} />
            </div>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <AppButton type="submit" label={"ارسال"} />
            </div>
          </form>
        </Paper>
      </Modal>
    </AppContainer>
  );
};

export default RequestLoan;
