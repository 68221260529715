import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";
import { UserManager } from "../../Utilities/UserManager";
import { User } from "../../Models/AppModels/User";
import Routing from "../../Routing/routing";
import AppDrawer from "../core/Drawer/App-Drawer";
import AppBottomNavigation from "../core/BottomNavigation/App-Bottom-Navigation";
import { sessionStorageHelper } from "../../Utilities/Helpers/sessionStorageHelper";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import AppMainToolbar from "../core/Toolbar/App-Main-Toolbar";

const Main = () => {
  const bottomNavigationLastValueCacheKey = "bottomNavigationLastValue";
  const navigate = useNavigate();
  const [bottomNavValue, setBottomNavValue] = useState<number>(0);
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [user, setuser] = useState<User | null>(null);
  const [finishStatus, setFinishStatus] = useState<boolean>(false);

  useEffect(() => {
    setUserInfoOnDrawer();
    checkBottomNavigationValue();
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm(AppMessages.WantToExitAppQuestion)) {
        setFinishStatus(true);
        navigate(Routing.Splash);
      } else {
        window.history.pushState(null, "", window.location.pathname);
        setFinishStatus(false);
      }
    }
  };

  const setUserInfoOnDrawer = () => {
    const userInfo: User = UserManager.get().loadUser();
    if (userInfo) {
      setuser(userInfo);
    }
  };

  const runBottomNavigationRouting = (bottomNavValue: number) => {
    if (bottomNavValue === 0) {
      navigate(Routing.Home);
    } else if (bottomNavValue === 1) {
      navigate(Routing.Chat);
    } else if (bottomNavValue === 2) {
      navigate(Routing.SendIdea);
    } else if (bottomNavValue === 3) {
      //navigate(Routing.Profile);
    }
  };

  const checkBottomNavigationValue = () => {
    let cachedBottomNavigationValue = sessionStorageHelper.load(
      bottomNavigationLastValueCacheKey
    );
    if (cachedBottomNavigationValue) {
      cachedBottomNavigationValue = parseInt(cachedBottomNavigationValue);
      setBottomNavValue(cachedBottomNavigationValue);
      runBottomNavigationRouting(cachedBottomNavigationValue);
    } else {
      navigate(Routing.Home, { replace: true });
    }
  };

  const handleBottomNavigationChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setBottomNavValue(newValue);
    sessionStorageHelper.store(bottomNavigationLastValueCacheKey, newValue);
    runBottomNavigationRouting(newValue);
  };

  const handleDrawerOpen = () => {
    setDrawerIsOpen(true);
  };

  const handleProfileListItemClick = () => {
    navigate(Routing.Profile, { replace: true });
  };

  return (
    <Box>
      <AppMainToolbar handleDrawer={handleDrawerOpen} />
      <AppDrawer
        onClose={() => setDrawerIsOpen(false)}
        onOpen={() => setDrawerIsOpen(true)}
        open={drawerIsOpen}
        mobile={user?.mobile}
        fullName={user?.getFullName()}
        onClick={handleProfileListItemClick}
      />
      <Outlet />
      <Grid item xs={12} md={12}>
        <AppBottomNavigation
          value={bottomNavValue}
          onChange={(event, newValue) =>
            handleBottomNavigationChange(event, newValue)
          }
        />
      </Grid>
    </Box>
  );
};

export default Main;
