import { useEffect, useState } from "react";
import AppContainer from "../core/AppContainer";
import { CardMedia } from "@material-ui/core";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import { useNavigate, useLocation } from "react-router-dom";

const VideoPlayer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [link, setLink] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    setLink(location.state.link);
    setTitle(location.state.title);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <AppContainer isLoadingSpinner={false}>
      <AppToolbar
        title={""}
        onClickBack={handleGoBack}
      />
      <div className="root">
        <CardMedia
          style={{ minHeight: "100vh", width: "100%", backgroundColor: "#000" }}
          component="video"
          autoPlay
          title={title}
          controls
          src={link}
        />
      </div>
    </AppContainer>
  );
};

export default VideoPlayer;
