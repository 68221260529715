import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../../Utilities/GlobalConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url("/Images/bg_background.png")`,
    height: "100vh",
  },
  boxStyles: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    backgroundColor: "#ffffff",
  },
  btn: {
    color: GlobalConstants.PrimaryColor,
    padding: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    cursor: "pointer",
  },
  divHeader: {
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    padding: "10px",
    backgroundColor: GlobalConstants.PrimaryColor,
    height: "50px",
    borderBottomLeftRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  textHeader: {
    color: "#fff",
  },
  textMessage: {
    color: "#000",
    padding: "30px",
  },
  divButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "15px",
  },
  secondaryMessage: {
    marginRight: "6%",
    marginLeft: "6%",
  },
}));

export default useStyles;
