import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Remote from "../../httpService/Remote";
import ApiService from "../../httpService/ApiService";
import IApiResultData from "../../Models/IApiResultData";
import AppContainer from "../core/AppContainer";
import useStyles from "./home.styles";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../Utilities/Toast";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import AppButtonOutline from "../core/App-Button/App-Button-Outline";
import ResponseLoanDto from "../../Models/AppModels/Loan/ResponseLoanDto";
import AppLabelTitle from "../core/Label/App-Label-Title";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import AppLabelContent from "../core/Label/App-Label-Content";
import { Cell, Legend, Pie, PieChart } from "recharts";
import Routing from "../../Routing/routing";
import { NumberHelper } from "../../Utilities/NumberHelper";
import ResponseCurrentLoanRequestDto from "../../Models/AppModels/Loan/ResponseCurrentLoanRequestDto";
import {
  GetLoanRequestStatusById,
  LoanRequestStatusType,
  LoanRequestStatusTypeLabel,
} from "../../Models/Enums/LoanRequestStatusType";
import UserMembershipInfoDto from "../../Models/AppModels/UserMembershipInfoDto";

const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  let [userMembership, setUserMembership] = useState<UserMembershipInfoDto>();
  let [dataLoan, setDataLoan] = useState<ResponseLoanDto>();
  let [dataLoanChart, setDataLoanChart] =
    useState<{ name: string; value: number }[]>();
  const colorChart = ["#00C49F", "#FF8042"];

  useEffect(() => {
    GetHomeData();
  }, []);

  const GetHomeData = () => {
    GetUserLoan();
    GetMembershipInfo();
  };

  const GetUserLoan = () => {
    Remote.call(
      ApiService.getUserLoan(),
      (response: IApiResultData<ResponseLoanDto>) => {
        console.log(response);
        if (response.data) {
          setDataLoan(response.data);
          setDataLoanChart([
            {
              name: AppMessages.totalAmount,
              value: response.data.totalAmount ? response.data.totalAmount : 0,
            },
            {
              name: AppMessages.remainingAmount,
              value: response.data.remainingAmount
                ? response.data.remainingAmount
                : 0,
            },
          ]);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const GetMembershipInfo = () => {
    Remote.call(
      ApiService.getMembershipFeeInfo(),
      (response: IApiResultData<UserMembershipInfoDto>) => {
        if (response.data) {
          setUserMembership(response.data);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const onClickRequestLoan = () => {
    if (dataLoan) {
      Toast.show("به دلیل داشتن وام جاری امکان درخواست وجود ندارد");
    } else {
      getUserCurrentLoanRequest();
    }
  };

  const onClickMyGuarantor = () => {
    navigate(Routing.MyGuarantor);
  };

  const onClickGuarantee = () => {
    navigate(Routing.Guarantee);
  };

  const getUserCurrentLoanRequest = () => {
    Remote.call(
      ApiService.getUserCurrentLoanRequest(),
      (response: IApiResultData<ResponseCurrentLoanRequestDto>) => {
        if (response.data && response.data.loanRequestStatus) {
          const status = response.data.loanRequestStatus;

          if (status === LoanRequestStatusType.InitialSubmit) {
            navigate(Routing.GuarantorRequestLoan);
          } else if (status === LoanRequestStatusType.GuarantorIntroduction) {
            Toast.show(
              ` وضعیت درخواست وام فعلی : 
                  ${LoanRequestStatusTypeLabel.get(status)}`
            );
          } else if (status <= LoanRequestStatusType.GuarantorApproval) {
            Toast.show(
              "ضامن درخواست شما را قبول کرده است، مرحله بعد ثبت درخواست"
            );
          } else if (status <= LoanRequestStatusType.RequestSubmit) {
            Toast.show(
              "درخواست شما ثبت شده است، مرحله بعد صف پرداخت وام می باشد"
            );
          } else if (status <= LoanRequestStatusType.PaymentQueue) {
            Toast.show("وام شما در صف پرداخت قرار دارد");
          } else if (status <= LoanRequestStatusType.Paid) {
            Toast.show("وام شما پرداخت شده است");
          } else {
            Toast.show(
              ` وضعیت درخواست وام فعلی : 
                            ${LoanRequestStatusTypeLabel.get(status)}`
            );
          }
        } else {
          navigate(Routing.RequestLoan);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const renderBarChart = (
    <Grid container style={{ display: "flex", justifyContent: "center" }}>
      <PieChart width={320} height={320}>
        <Pie
          data={dataLoanChart}
          innerRadius={90}
          outerRadius={100}
          paddingAngle={2}
          animationDuration={1500}
          dataKey="value"
        >
          {dataLoanChart?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colorChart[index % colorChart.length]}
            />
          ))}
        </Pie>
        <Legend />
      </PieChart>
    </Grid>
  );

  const renderFundInfo = () => {
    if (userMembership == null) {
      return (
        <AppGridItem all={12} style={{ paddingTop: "10px" }}>
          <AppLabelTitle label={AppMessages.dataNotFound} />
        </AppGridItem>
      );
    } else {
      return (
        <AppGridItem
          style={{ textAlign: "center" }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <AppLabelContent
            label={AppMessages.availableAmount}
            style={{ textAlign: "center" }}
          />
          <Grid style={{ paddingTop: "5px", textAlign: "center" }}>
            <AppLabelTitle
              style={{
                color: GlobalConstants.PrimaryColor,
                textAlign: "center",
              }}
              label={NumberHelper.get(userMembership.totalAmount)}
            />
          </Grid>
        </AppGridItem>
      );
    }
  };

  const renderCurrentLoanDetails = () => {
    if (!dataLoan) {
      return (
        <AppGridItem style={{ paddingTop: "10px" }}>
          <AppLabelTitle label={AppMessages.loanNotFound} />
        </AppGridItem>
      );
    } else {
      return (
        <>
          <AppGridItem xs={12} sm={6} md={3} lg={3}>
            <AppLabelContent style={{ textAlign: "center" }} label="وضعیت" />
            <Grid style={{ paddingTop: "5px" }}>
              <AppLabelTitle
                style={{
                  color: GlobalConstants.PrimaryColor,
                  textAlign: "center",
                }}
                label={GetLoanRequestStatusById(dataLoan.status)}
              />
            </Grid>
          </AppGridItem>

          {dataLoan.status === LoanRequestStatusType.InitialSubmit && (
            <AppGridItem xs={12} sm={6} md={3} lg={3}>
              <AppLabelContent style={{ textAlign: "center" }} />
              <Grid style={{ paddingTop: "5px" }}>
                <AppButtonOutline
                  className={classes.btnOutline}
                  label={AppMessages.guarantorIntroduction}
                  onClick={() => navigate(Routing.GuarantorRequestLoan)}
                />
              </Grid>
            </AppGridItem>
          )}

          <AppGridItem xs={12} sm={6} md={3} lg={3}>
            <AppLabelContent
              style={{ textAlign: "center" }}
              label={AppMessages.totalAmount}
            />
            <Grid style={{ paddingTop: "5px" }}>
              <AppLabelTitle
                style={{
                  color: GlobalConstants.PrimaryColor,
                  textAlign: "center",
                }}
                label={NumberHelper.get(dataLoan.totalAmount)}
              />
            </Grid>
          </AppGridItem>

          <AppGridItem xs={12} sm={6} md={3} lg={3}>
            <AppLabelContent
              style={{ textAlign: "center" }}
              label={AppMessages.remainingAmount}
            />
            <Grid style={{ paddingTop: "5px" }}>
              <AppLabelTitle
                style={{
                  color: GlobalConstants.OnPrimaryColor,
                  textAlign: "center",
                }}
                label={NumberHelper.get(dataLoan.remainingAmount)}
              />
            </Grid>
          </AppGridItem>

          <AppGridItem xs={12} sm={6} md={3} lg={3}>
            <AppLabelContent
              style={{ textAlign: "center" }}
              label={AppMessages.amountOfInstallment}
            />

            {dataLoan.installmentDurationInMonth > 0 ? (
              <AppLabelContent
                style={{ textAlign: "center" }}
                label={`(${dataLoan.installmentDurationInMonth} ماهه)`}
              />
            ) : null}

            <Grid style={{ paddingTop: "5px" }}>
              <AppLabelTitle
                style={{
                  color: GlobalConstants.TextPrimaryColor,
                  textAlign: "center",
                }}
                label={NumberHelper.get(dataLoan.monthlyInstallmentAmount)}
              />
            </Grid>
          </AppGridItem>
        </>
      );
    }
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <Box style={{ width: "100%" }} className={classes.container}>
        <Grid container className={GlobalStyle().main}>
          <AppGridItem all={4} style={{ textAlign: "center" }}>
            <AppButtonOutline
              className={classes.btnOutline}
              label={AppMessages.RequestLoan}
              onClick={onClickRequestLoan}
            />
          </AppGridItem>
          <AppGridItem
            all={4}
            style={{ textAlign: "center", paddingBottom: "10px" }}
          >
            <AppButtonOutline
              className={classes.btnOutline}
              label={AppMessages.guarantee}
              onClick={onClickGuarantee}
            />
          </AppGridItem>

          <AppGridItem
            all={4}
            style={{ textAlign: "center", paddingBottom: "10px" }}
          >
            <AppButtonOutline
              className={classes.btnOutline}
              label={AppMessages.myGuarantor}
              onClick={onClickMyGuarantor}
            />
          </AppGridItem>

          {/**fund data*/}
          <AppGridItem
            all={12}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <AppLabelTitle
              className={classes.HeaderLoan}
              label={AppMessages.FundInfo}
            />
          </AppGridItem>

          {renderFundInfo()}

          {/**loan data*/}
          <AppGridItem
            all={12}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <AppLabelTitle
              className={classes.HeaderLoan}
              label={AppMessages.existingLoan}
            />
          </AppGridItem>

          {renderCurrentLoanDetails()}

          <AppGridItem all={12} style={{ padding: "0px" }}>
            {renderBarChart}
          </AppGridItem>
        </Grid>
      </Box>
    </AppContainer>
  );
};

export default Home;
