import * as React from "react";
import { MouseEventHandler } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import { AppMessages } from "../../../ApplicationMessages/AppMessages";
import {
  InfoOutlined,
  LanguageOutlined,
  ListAltOutlined,
  LiveHelpOutlined,
  PaymentOutlined,
  SwapHorizOutlined,
} from "@mui/icons-material";
import Avatar from "@material-ui/core/Avatar";
import { useStylesGeneral } from "./App-Drawer-MUI.styles";
import { GlobalConstants } from "./../../../Utilities/GlobalConstants";
import { useNavigate } from "react-router-dom";
import Routing from "../../../Routing/routing";

const AppDrawer = (props: {
  id?: string;
  open?: boolean;
  onClose: React.ReactEventHandler<{}>;
  onOpen: React.ReactEventHandler<{}>;
  fullName?: string;
  mobile?: string;
  onClick?: MouseEventHandler<any> | undefined;
}) => {
  const classes = useStylesGeneral();
  const navigate = useNavigate();

  const renderDrawerListItems = () => (
    <List className={classes.list}>
      <ListItem
        onClick={() => {
          navigate(Routing.ListRequestLoan);
        }}
        className={classes.ListItemStyles}
        button
      >
        <ListItemIcon>
          <ListAltOutlined />
        </ListItemIcon>
        <ListItemText primary={AppMessages.listRequestLoan} />
      </ListItem>

      <ListItem
        onClick={() => {
          navigate(Routing.PaymentInstallment);
        }}
        className={classes.ListItemStyles}
        button
      >
        <ListItemIcon>
          <PaymentOutlined />
        </ListItemIcon>
        <ListItemText primary={AppMessages.paymentInstallmentCurrentLoan} />
      </ListItem>

      <ListItem
        onClick={() => {
          navigate(Routing.Transactions);
        }}
        className={classes.ListItemStyles}
        button
      >
        <ListItemIcon>
          <SwapHorizOutlined />
        </ListItemIcon>
        <ListItemText primary={AppMessages.transactions} />
      </ListItem>

      <ListItem
        onClick={() => {
          navigate(Routing.Help);
        }}
        className={classes.ListItemStyles}
        button
      >
        <ListItemIcon>
          <LiveHelpOutlined />
        </ListItemIcon>
        <ListItemText primary={AppMessages.Help} />
      </ListItem>

      <ListItem
        onClick={() => {
          navigate(Routing.About);
        }}
        className={classes.ListItemStyles}
        button
      >
        <ListItemIcon>
          <InfoOutlined />
        </ListItemIcon>
        <ListItemText primary={AppMessages.AboutSokhan} />
      </ListItem>

      <ListItem
        onClick={() => {
          window.location.href = GlobalConstants.MainWebsiteUrl;
        }}
        className={classes.ListItemStyles}
        button
      >
        <ListItemIcon>
          <LanguageOutlined />
        </ListItemIcon>
        <ListItemText primary={AppMessages.MainWebsite} />
      </ListItem>
    </List>
  );

  const renderDrawerHeader = (
    fullName?: string,
    mobile?: string,
    onClick?: MouseEventHandler<any> | undefined
  ) => {
    return (
      <div className={classes.divDrawerHeader}>
        <List className={classes.list}>
          <ListItem onClick={onClick} className={classes.ListItemStyles} button>
            <Avatar
              alt={fullName}
              src="/Images/ic_user_icon.png"
              style={{ width: "60px", height: "60px" }}
            />
            <ListItemText
              classes={{
                primary: classes.ListItemTextWhite,
                secondary: classes.ListItemTextWhite,
              }}
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              primary={fullName}
              secondary={mobile}
            />
          </ListItem>
        </List>
        <Typography className={classes.bottomTypography} align="center">
          {GlobalConstants.Website}
        </Typography>
      </div>
    );
  };

  return (
    <SwipeableDrawer
      id={props.id ? props.id : "AppDrawer"}
      anchor="right"
      open={props.open ? props.open : false}
      onClose={props.onClose}
      onOpen={props.onOpen}
    >
      {renderDrawerHeader(props.fullName, props.mobile, props.onClick)}
      {renderDrawerListItems()}
      <div className={classes.bottomOfDrawer}>
        {AppMessages.AllRightsReserved}
      </div>
    </SwipeableDrawer>
  );
};

export default AppDrawer;
