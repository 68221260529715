import { useNavigate } from "react-router-dom";
import AppContainer from "../core/AppContainer";
import { Container, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserManager } from "../../Utilities/UserManager";
import Routing from "../../Routing/routing";
import IApiResultData from "../../Models/IApiResultData";
import { User } from "../../Models/AppModels/User";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import useStyles from "./Splash.styles";
import {
  AppMessages,
  ErrorMessages,
} from "../../ApplicationMessages/AppMessages";
import { useEffect, useState } from "react";
import FirebaseHelper from "../../Utilities/Helpers/FirebaseHelper";
import AlertDialog from "../core/dialog/AlertDialog";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import { Toast } from "../../Utilities/Toast";

const Splash = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showDialogRetry, setShowDialogRetry] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      checkRegister();
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const checkRegister = () => {
    try {
      if (UserManager.get().isToken()) {
        CallGetUserInfo();
      } else {
        goToLogin();
      }
    } catch (error) {
      goToLogin();
    }
  };

  const CallGetUserInfo = () => {
    Remote.call(
      ApiService.getUserInfo(),
      (response: IApiResultData<User>) => {
        if (response.isSuccess && response.data) {
          UserManager.get().saveUser(response.data);
          FirebaseHelper.updateFcmToken((value: String) => {
            goToHome();
          });
        } else {
          setShowDialogRetry(true);
        }
      },
      (onProgress: boolean) => {},
      (error: string) => {
        setShowDialogRetry(true);
      }
    );
  };

  const goToLogin = () => {
    UserManager.get().deleteUserData();
    navigate(Routing.Login);
  };

  const goToHome = () => {
    navigate(Routing.Main);
  };

  const dialogResponse = (res: any) => {
    if (res === true) {
      checkRegister();
    } else {
      goToLogin();
    }
  };

  return (
    <AppContainer isLoadingSpinner={false}>
      <AlertDialog
        message={ErrorMessages.ErrorConnection}
        secondaryMessage={AppMessages.SplashDialogHint}
        open={showDialogRetry}
        positive={AppMessages.TryAgain}
        negative={AppMessages.EnterAccount}
        onChange={setShowDialogRetry}
        onResponse={dialogResponse}
      />
      <Container className={classes.container} maxWidth="xl">
        <Grid
          className={classes.centerItems}
          direction="column"
          alignItems="center"
          justifyContent="center"
          container
        >
          <div className={classes.HeaderIcons}>
            <Grid item xs={12} md={12}>
              <img
                alt=""
                className={classes.HeaderIconsImageSize}
                src="/Images/bg_splash_header.png"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <img
                alt=""
                className={classes.HeaderIconsImageSize}
                src="/Images/bg_flag.png"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <img
                alt=""
                className={classes.HeaderIconsImageSize}
                src="/Images/bg_splash_content.png"
              />
            </Grid>
            <div className={classes.textVersion}>{AppMessages.AppVersion}</div>
            <CircularProgress
              style={{
                color: GlobalConstants.OnPrimaryColor,
                width: "25px",
                height: "25px",
              }}
            />
          </div>
        </Grid>
      </Container>
      <Grid item xs={12} md={12}>
        <div className={classes.bottomSection}>
          {AppMessages.AllRightsReserved}
        </div>
      </Grid>
    </AppContainer>
  );
};

export default Splash;
