export enum EmploymentType {
  Official = 1,
  Peymani = 2,
  Contractual = 3,
  Other = 4,
}

export const EmploymentTypeLabel = new Map<number, string>([
  [EmploymentType.Official, "رسمی"],
  [EmploymentType.Peymani, "پیمانی"],
  [EmploymentType.Contractual, "قراردادی"],
  [EmploymentType.Other, "سایر"],
]);
