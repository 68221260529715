import * as React from "react";
import { Typography } from "@mui/material";
import useStyles from "./App-Label.styles";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";

const AppLabel = (props: {
  label?: string;
  style?: React.CSSProperties;
  dir?: string;
  hidden?: boolean;
  className?: string;
  variant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
}) => {
  const classes = useStyles();
  if (props.hidden) return <div />;
  return (
    <div className={classes.div}>
      <Typography
        style={props.style}
        dir={props.dir ? props.dir : "rtl"}
        variant={props.variant}
        className={props.className}
      >
        {props.label}
      </Typography>
    </div>
  );
};

export default AppLabel;
