import React, { UIEvent, useEffect, useState } from "react";

import { Container, Grid, List } from "@material-ui/core";
import AppContainer from "../core/AppContainer";
import IApiResultData from "../../Models/IApiResultData";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import { useNavigate } from "react-router-dom";
import Routing from "./../../Routing/routing";
import { Toast } from "../../Utilities/Toast";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { NumberHelper } from "../../Utilities/NumberHelper";
import AppLabelTitle from "../core/Label/App-Label-Title";
import AppLabelContent from "../core/Label/App-Label-Content";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import { LayoutNoData } from "../core/LayoutNoData";
import useStyles from "./ListRequestLoan.styles";
import IPagedResultData from "../../Models/IPagedResultData";
import ResponseListRequestLoanDto from "../../Models/AppModels/ListRequestLoan/ResponseListRequestLoanDto";
import { LoanRequestStatusTypeLabel } from "../../Models/Enums/LoanRequestStatusType";

const ListRequestLoan = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState<ResponseListRequestLoanDto[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getListRequestLoan(page);
  }, []);

  const getListRequestLoan = (page: number) => {
    if (page === 1 || hasMoreData) {
      Remote.call(
        ApiService.getListRequestLoan(page),
        (
          response: IApiResultData<IPagedResultData<ResponseListRequestLoanDto>>
        ) => {
          if (response.data) {
            const { items, totalPages } = response.data;
            setHasMoreData(totalPages > page);
            setDataList((prevDataList) =>
              page === 1 ? items : [...prevDataList, ...items]
            );
          }
        },
        (onProgress: boolean) => {
          setIsLoading(onProgress);
        },
        (error: string) => {
          Toast.show(error);
        }
      );
    }
  };

  const handleGoBack = () => {
    navigate(Routing.Main);
  };

  const handleScroll = (event: UIEvent<HTMLElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const diff = Math.abs(scrollHeight + scrollTop - clientHeight);
    if (diff >= 0 && diff <= 5 && hasMoreData) {
      setPage((prev) => prev + 1);
      getListRequestLoan(page);
    }
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AppToolbar
        title={AppMessages.listRequestLoan}
        onClickBack={handleGoBack}
      />
      <Container className={classes.container}>
        <Grid container className={GlobalStyle().main}>
          <LayoutNoData show={dataList.length !== 0} />
          <AppGridItem
            all={12}
            hidden={dataList.length === 0}
            style={{ padding: "0px" }}
          >
            <List onScroll={handleScroll}>
              {dataList.map((data) => (
                <Grid
                  container
                  key={data.id}
                  style={{
                    border: "1.5px solid #00897B",
                    borderColor: GlobalConstants.PrimaryColor,
                    borderRadius: "10px",
                    marginTop: "14px",
                    alignItems: "center",
                  }}
                >
                  <AppGridItem all={1}>
                    <AppLabelContent
                      label={`#`}
                      style={{
                        color: "#969696",
                        textAlign: "right",
                      }}
                    />
                    <AppLabelContent
                      label={data.id.toString()}
                      style={{ textAlign: "right" }}
                    />
                  </AppGridItem>

                  <AppGridItem all={2}>
                    <AppLabelContent
                      label={AppMessages.status}
                      style={{ textAlign: "right", color: "#969696" }}
                    />
                    <AppLabelTitle
                      label={`${LoanRequestStatusTypeLabel.get(data.status)}`}
                      style={{ textAlign: "right" }}
                    />
                  </AppGridItem>

                  <AppGridItem all={3}>
                    <AppLabelContent
                      label={`مبلغ وام`}
                      style={{
                        color: "#969696",
                        textAlign: "right",
                      }}
                    />
                    <AppLabelContent
                      label={NumberHelper.get(data.totalAmount)}
                      style={{ textAlign: "right" }}
                    />
                  </AppGridItem>

                  <AppGridItem all={3}>
                    <AppLabelContent
                      label={`قسط ماهانه`}
                      style={{ color: "#969696", textAlign: "right" }}
                    />
                    <AppLabelContent
                      label={NumberHelper.get(data.monthlyInstallmentAmount)}
                      style={{ textAlign: "right" }}
                    />
                  </AppGridItem>

                  <AppGridItem all={3}>
                    <AppLabelContent
                      label={`باقیمانده`}
                      style={{ textAlign: "right", color: "#969696" }}
                    />
                    <AppLabelContent
                      label={NumberHelper.get(data.remainingAmount)}
                      style={{ textAlign: "right" }}
                    />
                  </AppGridItem>
                </Grid>
              ))}
            </List>
          </AppGridItem>
        </Grid>
      </Container>
    </AppContainer>
  );
};

export default ListRequestLoan;
