import { BrowserRouter, Route, Routes } from "react-router-dom";
import Routing from "./Routing/routing";
import LoginOtp from "./components/LoginOtp/LoginOtp.component";
import Register from "./components/Register/Register.component";
import Splash from "./components/Splash/Splash.component";
import Main from "./components/Main/main.component";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.component";
import Profile from "./components/Profile/profile.component";
import AuthenticatedRedirectRoute from "./components/NotPrivateRoute/authenticatedRedirectRoute.component";
import PdfView from "./components/PdfView/pdf-view.component";
import VideoPlayer from "./components/VideoPlayer/video-player.component";
import SendReport from "./components/SendReport/sendReport.component";
import Help from "./components/Help/help.component";
import About from "./components/About/about.component";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary.component";
import ChatItem from "./components/ChatItem/chatItem.component";
import { CssBaseline } from "@material-ui/core";
import { ToastContainer } from "material-react-toastify";
import SendIdea from "./components/SendIdea/sendIdea.component";
import ChatList from "./components/ChatList/chatList.component";
import Home from "./components/Home/home.component";
import "material-react-toastify/dist/ReactToastify.css";
import RequestLoan from "./components/RequestLoan/RequestLoan.component";
import GuarantorRequestLoan from "./components/GuarantorRequestLoan/GuarantorRequestLoan.component";
import Guarantee from "./components/Guarantee/Guarantee.component";
import MyGuarantor from "./components/Guarantee/MyGuarantor.component";
import Transactions from "./components/Transactions/Transaction.component";
import PaymentResult from "./components/PaymentResult/PaymentResult.component";
import ListRequestLoan from "./components/ListRequestLoan/ListRequestLoan.component";
import PaymentInstallment from "./components/PaymentInstallment/PaymentInstallment.component";

const App = () => {
  return (
    <BrowserRouter basename="/">
      <ToastContainer rtl />
      <CssBaseline />
      <ErrorBoundary>
        <Routes>
          <Route index path={Routing.Splash} element={<Splash />} />

          <Route element={<PrivateRoute />}>
            <Route path={Routing.About} element={<About />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.Help} element={<Help />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.Profile} element={<Profile />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.SendReport} element={<SendReport />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.Main} element={<Main />}>
              <Route path={Routing.Home} element={<Home />} />
              <Route path={Routing.Chat} element={<ChatList />} />
              <Route path={Routing.SendIdea} element={<SendIdea />} />
            </Route>
          </Route>

          <Route path={Routing.ChatItem} element={<PrivateRoute />}>
            <Route path={Routing.ChatItem} element={<ChatItem />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.Player} element={<VideoPlayer />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.PdfView} element={<PdfView />} />
          </Route>

          <Route element={<AuthenticatedRedirectRoute />}>
            <Route path={Routing.Login} element={<LoginOtp />} />
          </Route>

          <Route element={<AuthenticatedRedirectRoute />}>
            <Route path={Routing.Register} element={<Register />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.RequestLoan} element={<RequestLoan />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path={Routing.GuarantorRequestLoan}
              element={<GuarantorRequestLoan />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.Guarantee} element={<Guarantee />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={Routing.MyGuarantor} element={<MyGuarantor />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={Routing.Transactions} element={<Transactions />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path={Routing.ListRequestLoan} element={<ListRequestLoan />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={Routing.PaymentInstallment} element={<PaymentInstallment />} />
          </Route>

          <Route
            path={`${Routing.PaymentResult}`}
            element={<PaymentResult />}
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
