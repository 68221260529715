export const GlobalConstants = {
    ConfirmCodeLength: 4,
    OtpResendRemainingTime: 60,
    AppName: "صندوق اعتماد",
    PrimaryColor: "#00897B",
    SecondaryColor: "#00796B",
    OnPrimaryColor: "#f59d2a",
    OnSecondaryColor: "#f5b25b",
    TextPrimaryColor: "#4d4d4d",
    TextSecondaryColor: "#f8f8f8",
    RedColor: "#F44336",
    GreenColor: "#4CAF50",
    headerRadius: "25px",
    PageSizeChatMessage: 20,
    ContentMarginTopFromAppbar: "50px",
    Website: "www.Etemad.mefa.ir",
    MainWebsiteUrl: "https://Etemad.mefa.ir:8082",
    UserFcmTopicLocalStorageKey: "userFcmTopic",
    unit: " ریال ",

};
