import { MouseEventHandler } from "react";
import { AppBar, CssBaseline, Toolbar, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Menu } from "@mui/icons-material";
import { GlobalConstants } from "../../../Utilities/GlobalConstants";
import { useStylesGeneral } from "./App-Main-Toolbar-MUI.styles";
import {PersianDateHelper} from "../../../Utilities/Helpers/PersianDateHelper";

const AppMainToolbar = (props: {
  id?: string;
  title?: string;
  handleDrawer?:  MouseEventHandler<any> | undefined;
}) => {
  const classes = useStylesGeneral();
  return (
      <AppBar className={classes.AppBar} position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
              onClick={props.handleDrawer}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
          >
            <Menu />
          </IconButton>

          <Typography className={classes.title} variant="h6" noWrap>
            {GlobalConstants.AppName}
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Typography className={classes.leftTitle} variant="h6" noWrap>
              {PersianDateHelper.getCurrentDate()}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
  );
};

export default AppMainToolbar;
