import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../../Utilities/GlobalConstants";

const width = 300;

export const useStylesGeneral = makeStyles((theme) => ({
  list: {
    width: width,
  },
  ListItemStyles: {
    textAlign: "right!important" as any,
  },
  divDrawerHeader: {
    backgroundColor: GlobalConstants.PrimaryColor,
    height: "200px",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
    paddingRight: "10px",
    paddingTop: "20px",
    borderBottomLeftRadius: "25%",
    borderBottomRightRadius: "25%",
  },
  drawerHeaderTypographyColor: {
    color: "#ffffff",
  },
  ListItemTextWhite: {
    color: "#ffffff",
  },
  bottomTypography: {
    color: "#d0d2d6",
    fontFamily: "Tahoma!important",
    fontSize: 11,
    textAlign: "center",
    marginTop: "65px",
    bottom: 0,
    left: 0,
    right: 0,
  },
  bottomOfDrawer: {
    width: width,
    fontSize: "11px",
    position: "fixed",
    padding: "10px",
    textAlign: "center",
    bottom: 0,
    left: 0,
    right: 0,
  },
}));
