import React, { useEffect, useState } from "react";

import { Container, Grid, List } from "@material-ui/core";
import useStyles from "./Guarantee.styles";
import AppContainer from "../core/AppContainer";
import IApiResultData from "../../Models/IApiResultData";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import { useNavigate } from "react-router-dom";
import Routing from "./../../Routing/routing";
import { Toast } from "../../Utilities/Toast";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import ResponseGuaranteeListDto from "../../Models/AppModels/Guarantee/ResponseGuaranteeListDto";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { NumberHelper } from "../../Utilities/NumberHelper";
import { DomainVerificationOutlined } from "@mui/icons-material";
import {
  GetLoanRequestGuarantorAcceptStatusById,
  LoanRequestGuarantorAcceptStatusType,
} from "../../Models/Enums/LoanRequestGuarantorAcceptStatusType";
import AppLabelTitle from "../core/Label/App-Label-Title";
import AppLabelContent from "../core/Label/App-Label-Content";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import { LayoutNoData } from "../core/LayoutNoData";

const MyGuarantor = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState<ResponseGuaranteeListDto[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getUserGuaranteeList();
  }, []);

  const getUserGuaranteeList = () => {
    Remote.call(
      ApiService.getUserLoanRequestGuarantorsList(),
      (response: IApiResultData<ResponseGuaranteeListDto[]>) => {
        if (response.data) {
          setDataList(response.data);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const handleGoBack = () => {
    navigate(Routing.Main);
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AppToolbar title={AppMessages.guarantor} onClickBack={handleGoBack} />
      <Container className={classes.container}>
        <Grid container className={GlobalStyle().main}>
          <LayoutNoData show={dataList.length !== 0} />
          <AppGridItem
            all={12}
            hidden={dataList.length === 0}
            style={{ padding: "0px" }}
          >
            <List>
              {dataList.map((data) => (
                <Grid
                  container
                  key={data.id}
                  style={{
                    border: "1.5px solid #00897B",
                    borderColor: GlobalConstants.PrimaryColor,
                    borderRadius: "10px",
                    marginTop: "14px",
                    alignItems: "center",
                  }}
                >
                  <AppGridItem all={1}>
                    <DomainVerificationOutlined
                      style={{
                        color:
                          data.loanRequestGuarantorAcceptStatus ===
                          LoanRequestGuarantorAcceptStatusType.Accepted
                            ? GlobalConstants.GreenColor
                            : GlobalConstants.RedColor,
                        width: "35px",
                        height: "35px",
                        alignContent: "center",
                      }}
                    />
                  </AppGridItem>
                  <AppGridItem all={11}>
                    <AppLabelTitle
                      label={`${data.guarantorName} ${data.guarantorFamily}`}
                      style={{
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    />
                    <AppLabelContent
                      label={` مبلغ: ${NumberHelper.get(data.amount)}`}
                      style={{
                        paddingLeft: "2px",
                        paddingRight: "2px",
                        color: "#969696",
                      }}
                    />
                    <AppLabelContent
                      label={`وضعیت: ${GetLoanRequestGuarantorAcceptStatusById(
                        data.loanRequestGuarantorAcceptStatus
                      )}`}
                      style={{
                        paddingLeft: "2px",
                        paddingRight: "2px",
                        color:
                          data.loanRequestGuarantorAcceptStatus ===
                          LoanRequestGuarantorAcceptStatusType.Accepted
                            ? GlobalConstants.GreenColor
                            : GlobalConstants.RedColor,
                      }}
                    />
                  </AppGridItem>
                </Grid>
              ))}
            </List>
          </AppGridItem>
        </Grid>
      </Container>
    </AppContainer>
  );
};

export default MyGuarantor;
