import {toast} from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

export const Toast = {
    show(msg: string) {
        const toastId = 124436634;
        if (toast.isActive(toastId)) {
            toast.dismiss(toastId)
            return
        }
        toast.dark(msg, {
            toastId: toastId,
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            bodyStyle: {lineHeight: "27px"},
            //transition: Zoom,
        });
    },
};
