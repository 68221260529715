import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: GlobalConstants.ContentMarginTopFromAppbar,
  },
  ListItemStyles: {
    textAlign: "center !important" as any,
    border: "0.8px solid #f57c00",
    borderRadius: "28%",
    width: "33%",
    height: "33%",
  },
  HeaderLoan: {
    border: "0.8px solid #eeeeee",
    backgroundColor: "#eeeeee",
    padding: "7px",
    borderRadius: "5px",
    color: GlobalConstants.TextPrimaryColor,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  btnOutline: {
    boxShadow: "none",
    borderColor: GlobalConstants.PrimaryColor,
    padding: "8px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40vw",
    },
  },
  divTextField: {
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
}));
export default useStyles;
