export enum LoanRequestStatusType {
  Canceled = -1,
  MaxLoanRequest = 1,
  InitialSubmit = 2,
  GuarantorIntroduction = 3,
  GuarantorApproval = 4,
  RequestSubmit = 5,
  PaymentQueue = 6,
  Paid = 7,
  PaidInstallment = 8,
}

export const LoanRequestStatusTypeLabel = new Map<
  LoanRequestStatusType,
  string
>([
  [LoanRequestStatusType.Canceled, "لغو شده"],
  [LoanRequestStatusType.MaxLoanRequest, "درخواست سقف"],
  [LoanRequestStatusType.InitialSubmit, "ثبت اولیه"],
  [LoanRequestStatusType.GuarantorIntroduction, "معرفی ضامن"],
  [LoanRequestStatusType.GuarantorApproval, "تایید ضامن"],
  [LoanRequestStatusType.RequestSubmit, "ثبت درخواست"],
  [LoanRequestStatusType.PaymentQueue, "در صف پرداخت وام"],
  [LoanRequestStatusType.Paid, "پرداخت شده"],
  [LoanRequestStatusType.PaidInstallment, "اتمام اقساط"],
]);

export const GetLoanRequestStatusById = (id: number): string => {
  return (
    LoanRequestStatusTypeLabel.get(id as LoanRequestStatusType) ?? "ناشناخته"
  );
};
