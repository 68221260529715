import { Backdrop, Box, Fade, Modal, Typography } from "@material-ui/core";
import * as React from "react";
import { AppMessages } from "../../../ApplicationMessages/AppMessages";
import { GlobalConstants } from "../../../Utilities/GlobalConstants";
import useStyles from "./dialog.styles";

const AlertDialog = (props: {
  title?: string;
  message?: string;
  secondaryMessage?: string;
  positive?: string;
  negative?: string;
  open?: boolean;
  onChange?: React.Dispatch<React.SetStateAction<boolean>>;
  onResponse?: (value: boolean) => void;
}) => {
  const classes = useStyles();
  const handleClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    if (props.onChange != null) {
      props.onChange(false);
    }
  };

  const onPositive = () => {
    handleClose(null, null);
    if (props.onResponse != null) {
      props.onResponse(true);
    }
  };

  const onNegative = () => {
    handleClose(null, null);
    if (props.onResponse != null) {
      props.onResponse(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open ? props.open : false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open ? props.open : false}>
          <Box className={classes.boxStyles}>
            <div className={classes.divHeader}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className={classes.textHeader}
              >
                {props.title ? props.title : GlobalConstants.AppName}
              </Typography>
            </div>
            <Typography
              id="transition-modal-description"
              className={classes.textMessage}
            >
              {props.message ? props.message : ""}
            </Typography>

            {props.secondaryMessage ? (
              <Typography
                className={classes.secondaryMessage}
                component="p"
                id="transition-modal-description"
              >
                {props.secondaryMessage ? props.secondaryMessage : ""}
              </Typography>
            ) : null}

            <div className={classes.divButton}>
              <Typography
                id="transition-modal-positive"
                variant="h6"
                component="h2"
                className={classes.btn}
                onClick={onPositive}
              >
                {props.positive ? props.positive : AppMessages.accept}
              </Typography>

              <Typography
                id="transition-modal-negative"
                hidden={props.negative == null}
                variant="h6"
                component="h2"
                className={classes.btn}
                onClick={onNegative}
              >
                {props.negative ? props.negative : AppMessages.Cancel}
              </Typography>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AlertDialog;
