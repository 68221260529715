import PaymentInstallment from "../components/PaymentInstallment/PaymentInstallment.component";

const Url = {
  CheckRegister: "client/CheckRegister",
  Register: "client/Register",
  ConfirmCode: "client/ConfirmCode",
  ResendConfirmCode: "client/ResendConfirmCode",
  GetUserInfo: "client/GetUserInfo",
  RefreshToken: "client/RefreshToken",
  SendIdeaAndSuggestion: "client/SendIdeaAndSuggestion",
  EditProfile: "Client/EditProfile",
  SendReport: "Client/SendReport",
  GetBaseSettingData: "Client/GetBaseSettingData",
  LogError: "Client/LogClientError",
  UpdateFcmToken: "Client/UpdateFcmToken",
  GetUserFcmTopic: "Client/GetUserFcmTopic",
  SetFcmTopicSubscription: "Client/SetFcmTopicSubscription",
  GetJobLocationList: "Client/GetJobLocationList",
  GetTicketItems: "Tickets/GetTicketItems",
  GetTicketMessages: "Tickets/GetTicketMessages",
  CreateNewTicketMessage: "Tickets/CreateNewTicketMessage",
  GetData: "DataInfo/GetData",
  GetDataById: "DataInfo/GetDataById",
  SearchData: "DataInfo/SearchDataInfo",
  GetDataHasChild: "DataInfo/DataHasChild",

  ////////////////////////

  getUserLoan: "Fund/GetUserLoan",
  getMembershipFeeInfo: "Fund/GetUserMembershipInfo",
  getUserReceivableLoanInfo: "Fund/GetUserReceivableLoanInfo",
  getUserCurrentLoanRequest: "Fund/GetUserCurrentLoanRequest",
  createRequestLoanInitial: "Fund/CreateRequestLoanInitial",
  requestMaxLoan: "Fund/RequestMaxLoan",
  calculateMaxLoanDifference: "Fund/CalculateMaxLoanDifference",
  addGuarantor: "Fund/AddGuarantor",
  getUserGuaranteeList: "Fund/GetUserGuaranteeList",
  getUserLoanRequestGuarantorsList: "Fund/GetUserLoanRequestGuarantorsList",
  setGuarantorStatus: "Fund/SetGuarantorStatus",
  getTransactions: "Transactions/GetTransactions",

  getLoanRequestsList: "Fund/GetLoanRequestsList",
  submitCurrentLoanInstallmentReceiptByUser:
    "Fund/SubmitCurrentLoanInstallmentReceiptByUser",
};

export default Url;
