import {Box, Grid} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {AppMessages} from "../../ApplicationMessages/AppMessages";
import Routing from "../../Routing/routing";
import AppContainer from "../core/AppContainer";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import useStyles from "./about.styles";
import {GlobalStyle} from "../../Utilities/GlobalStyle";
import AppGridItem from "../core/Grid/App-Grid-Item";

const About = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(Routing.Main);
    };

    return (
        <AppContainer isLoadingSpinner={false}>
            <AppToolbar
                title={AppMessages.AboutSokhan}
                onClickBack={handleGoBack}
            />
            <Box className={classes.container}>
                <Grid container className={GlobalStyle().main}>
                    <div className={classes.content}>
                        {AppMessages.AboutSokhanDescription}
                    </div>
                </Grid>
            </Box>
        </AppContainer>
    );
};

export default About;
