import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { EmploymentType } from "../../Models/Enums/EmploymentType";

const EmploymentTypeList = [
  { id: 0, value: AppMessages.Choose },
  { id: EmploymentType.Official, value: "رسمی" },
  { id: EmploymentType.Peymani, value: "پیمانی" },
  { id: EmploymentType.Contractual, value: "قراردادی" },
  { id: EmploymentType.Other, value: "سایر" },
];

export default EmploymentTypeList;
