import { MouseEventHandler } from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ArrowForward } from "@mui/icons-material";
import { useStylesGeneral } from "./App-Toolbar-MUI.styles";

const AppToolbar = (props: {
  id?: string;
  title?: string;
  src?: string;
  onClickBack?: MouseEventHandler<any> | undefined;
}) => {
  const classes = useStylesGeneral();
  return (
    <AppBar className={classes.AppBar} position="fixed" elevation={0}>
      <Toolbar id={props.id ? props.id : "AppToolbar"}>
        <IconButton
          onClick={props.onClickBack}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <ArrowForward />
        </IconButton>
        <div hidden={!(props.src != null && props.src.length > 0)}>
          <img src={props.src} alt={props.title} className={classes.icon} />
        </div>
        <Typography className={classes.Title} variant="h6">
          {props.title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default AppToolbar;
