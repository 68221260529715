import { AppMessages } from "../../ApplicationMessages/AppMessages";

export enum JobTypeEnum {
  Employed = 1,
  Retired = 2,
}

export const JobTypeLabel = new Map<number, string>([
  [JobTypeEnum.Employed, AppMessages.Employed],
  [JobTypeEnum.Retired, AppMessages.Retired],
]);
