import React, {MouseEventHandler, ReactNode} from "react";
import {Button} from "@material-ui/core";
import {GlobalStyle} from "../../../Utilities/GlobalStyle";
import useStyles from "./App-Button.styles";

const AppButtonIcon = (props: {
    id?: string;
    style?: React.CSSProperties;
    dir?: string;
    disable?: boolean;
    hidden?: boolean;
    className?: string;
    icon: ReactNode;
    onClick?: MouseEventHandler<any> | undefined;
}) => {
    const classes = useStyles();
    if (props.hidden) return <div/>;
    let btnClass = GlobalStyle().btnPrimary;
    if (props.className != null) btnClass = props.className;
    return (
        <div hidden={props.hidden}>
            <Button
                fullWidth={true}
                name={props.id}
                className={btnClass}
                style={props.style}
                type="button"
                onClick={props.onClick}
                variant="contained"
            >
                {props.icon}
            </Button>
        </div>
    );
};
export default AppButtonIcon;
