import firebase from "firebase/compat/app";
import { getMessaging, getToken } from "firebase/messaging";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import IApiResult from "../../Models/IApiResult";
import IApiResultData from "../../Models/IApiResultData";
import { GlobalConstants } from "../GlobalConstants";
import { UserManager } from "./../UserManager";
import { localStorageHelper } from "./localStorageHelper";

class FirebaseHelper {
  private firebaseConfig = {
    apiKey: "AIzaSyCrEDDi3JvHOA9YDwowYYywp2n2q-LLK3U",
    authDomain: "sokhan-92c2c.firebaseapp.com",
    projectId: "sokhan-92c2c",
    storageBucket: "sokhan-92c2c.appspot.com",
    messagingSenderId: "306929909610",
    appId: "1:306929909610:web:a715d12de9b8c470b3af13",
    measurementId: "G-R25HVMDNTG",
  };
  private vapidKey = `BMG1vf96h3BPqjuGXMUCK86TpySvIQBHveeWsIDn74I8JmOZu2SV6RXvDXDWoeTfeA12Lis64bWvxv6DsouEBj0`;
  private app = firebase.initializeApp(this.firebaseConfig);
  private messaging = getMessaging(this.app);

  async updateFcmToken(onSuccess?: (value: String) => void) {
    try {
      const currentToken = await getToken(this.messaging, {
        vapidKey: this.vapidKey,
      });
      if (currentToken) {
        this.SendTokenToServer(currentToken, onSuccess);
      } else {
        this.success("Not permission Notify", onSuccess);
      }
    } catch (err) {
      this.success("An error occurred while retrieving token.", onSuccess);
    }
  }

  private SendTokenToServer(
    currentToken: string,
    onSuccess?: (value: String) => void
  ) {
    Remote.call(
      ApiService.updateFcmToken({
        fcmToken: currentToken,
        refreshToken: UserManager.get().loadToken().refreshToken,
      }),
      (response: IApiResultData<boolean>) => {
        this.success("success", onSuccess);
      },
      (onProgress: boolean) => {},
      (error: string) => {
        this.success(error, onSuccess);
      }
    );
  }

  async subscribeToTopic(onSuccess?: (value: String) => void) {
    try {
      const currentToken = await getToken(this.messaging, {
        vapidKey: this.vapidKey,
      });

      var dataUserFcmTopic = localStorageHelper.load(
        GlobalConstants.UserFcmTopicLocalStorageKey
      );
      if (!dataUserFcmTopic) {
        Remote.call(
          ApiService.getUserFcmTopic(),
          (response: IApiResultData<string>) => {
            if (response.data) {
              localStorageHelper.store(
                GlobalConstants.UserFcmTopicLocalStorageKey,
                response.data
              );

              Remote.call(
                ApiService.setFcmTopicSubscription(currentToken, ""),
                (response: IApiResult) => {
                  this.success("success", onSuccess);
                },
                (onProgress: boolean) => {},
                (error: string) => {
                  this.success(error, onSuccess);
                }
              );
            }
          },
          (onProgress: boolean) => {},
          (error: string) => {
            this.success(error, onSuccess);
          }
        );
      } else {
        Remote.call(
          ApiService.getUserFcmTopic(),
          (response: IApiResultData<string>) => {
            if (response.data) {
              if (response.data !== dataUserFcmTopic) {
                var newFcmTopic = response.data;
                Remote.call(
                  ApiService.setFcmTopicSubscription(
                    currentToken,
                    dataUserFcmTopic
                  ),
                  (response: IApiResult) => {
                    localStorageHelper.store(
                      GlobalConstants.UserFcmTopicLocalStorageKey,
                      newFcmTopic
                    );
                    this.success("success", onSuccess);
                  },
                  (onProgress: boolean) => {},
                  (error: string) => {
                    this.success(error, onSuccess);
                  }
                );
              } else {
                this.success("success", onSuccess);
              }
            }
          },
          (onProgress: boolean) => {},
          (error: string) => {
            this.success(error, onSuccess);
          }
        );
      }
    } catch (err) {
      this.success("An error occurred while retrieving token.", onSuccess);
    }
  }

  private success(value: String, onSuccess?: (value: String) => void) {
    if (onSuccess != null) {
      onSuccess("=> " + value);
    }
  }
}

export default new FirebaseHelper();
