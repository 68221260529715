import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import {
  AppMessages,
  ErrorMessages,
} from "../../ApplicationMessages/AppMessages";
import useStyles from "./LoginOtp.styles";
import { useNavigate } from "react-router-dom";
import Routing from "../../Routing/routing";
import AppContainer from "../core/AppContainer";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import IApiResultData from "../../Models/IApiResultData";
import AppButton from "../core/App-Button/App-Button";
import AppTextField from "../core/TextField/App-Text_field";
import { CheckRegisterResult } from "../../Models/AppModels/CheckRegisterResult";
import VerifyOtp from "../VerifyOtp/VerifyOtp.component";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { Toast } from "../../Utilities/Toast";
import { Validation } from "../../Utilities/Validators/Validation";

const LoginOtp = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [nationalCode, setNationalCode] = useState<string>("");
  const [showConfirmPhoneNumberSection, setShowConfirmPhoneNumberSection] =
    useState<boolean>(false);

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value: string = event.target.value;
    setPhoneNumber(value);
  };
  const handleNationalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value: string = event.target.value;
    setNationalCode(value);
  };

  const onClickSubmit = () => {
    if (!Validation.isMobile(phoneNumber)) {
      Toast.show(ErrorMessages.IncorrectPhoneNumber);
      return;
    }

    if (!Validation.isNationalCode(nationalCode)) {
      Toast.show(ErrorMessages.IncorrectNationalCode);
      return;
    }

    CallOtpApi();
  };

  const CallOtpApi = () => {
    Remote.call(
      ApiService.checkRegister(phoneNumber, nationalCode),
      (response: IApiResultData<CheckRegisterResult>) => {
        console.log(response);
        if (response.data) {
          if (response.data.redirectToConfirmCode) {
            setShowConfirmPhoneNumberSection(true);
          } else if (response.data.redirectToRegister) {
            navigate(Routing.Register, {
              state: {
                phoneNumber,
                nationalCode,
              },
            });
          } else {
            Toast.show(response.message);
          }
        } else {
          Toast.show(response.message);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const loginElements = (
    <Box style={{ width: "100%" }}>
      <Grid container className={GlobalStyle().main}>
        <AppGridItem all={12}>
          <AppTextField
            id="phoneNumber"
            inputProps={{
              style: { textAlign: "center" },
              maxLength: 11,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            label={AppMessages.phoneNumber}
            className={classes.divTextField}
            required={true}
            onChange={handlePhoneNumberChange}
          />
        </AppGridItem>
        <AppGridItem all={12}>
          <AppTextField
            id="nationalCode"
            inputProps={{
              style: { textAlign: "center" },
              maxLength: 10,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            label={AppMessages.NationalCode}
            className={classes.divTextField}
            required={true}
            onChange={handleNationalCodeChange}
          />
        </AppGridItem>
        <AppGridItem all={12}>
          <AppButton
            type="button"
            className={GlobalStyle().btnAuth}
            style={{ marginTop: "20px" }}
            label={AppMessages.accept}
            onClick={onClickSubmit}
          />
        </AppGridItem>
      </Grid>
    </Box>
  );

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <Grid item className={classes.containerHeader}>
        <Grid
          className={classes.centerItems}
          direction="column"
          alignItems="center"
          justifyContent="center"
          container
        >
          <Grid item xs={12} md={12} className={classes.HeaderIcons}>
            <img
              alt=""
              className={classes.HeaderIconsImageSize}
              src="/Images/bg_splash_content.png"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.mainPart}>
          {showConfirmPhoneNumberSection ? (
            <VerifyOtp phoneNumber={phoneNumber} />
          ) : (
            loginElements
          )}
        </div>
      </Grid>
    </AppContainer>
  );
};

export default LoginOtp;
