import { makeStyles } from "@material-ui/core";
import {GlobalConstants} from "../../Utilities/GlobalConstants";
import {GlobalStyle} from "../../Utilities/GlobalStyle";
const useStyles = makeStyles((theme) => ({
  container: {

  },
  containerHeader: {
    borderBottomRightRadius:"50px",
    borderBottomLeftRadius:"50px",
    backgroundImage: `url("/Images/bg_background.png")`,
    backgroundColor: GlobalConstants.PrimaryColor,
  },
  centerItems: {
    alignItems: "center",
  },
  HeaderIcons: {
    alignItems: "center",
    textAlign: "center",
    marginTop: GlobalConstants.headerRadius,
    marginBottom: GlobalConstants.headerRadius,
  },
  HeaderIconsImageSize: {
    width: "40%",
  },
  mainPart: {
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    marginTop: "30px",
  },
  divTextField:{
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
}));
export default useStyles;
