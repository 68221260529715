import { GlobalConstants } from "./GlobalConstants";

const addCommas = (num: string) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const removeNonNumeric = (num: number) => num.toString().replace(/[^0-9]/g, "");

export const NumberHelper = {
  separateComma(number: number): string {
    return addCommas(removeNonNumeric(number));
  },
  get(number: number | undefined): string {
    if (!number) return `0 ${GlobalConstants.unit}`;
    return `${addCommas(removeNonNumeric(number))} ${GlobalConstants.unit}`;
  },
};
