import * as React from "react";
import {Typography} from "@mui/material";
import useStyles from "./App-Label.styles";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import AppLabel from "./App-Label";
import {GlobalStyle} from "../../../Utilities/GlobalStyle";


const AppLabelHeader = (props: {
    label?: string;
    style?: React.CSSProperties;
    dir?: string;
    hidden?: boolean;
    className?: string;
}) => {
    if (props.hidden) return <div/>;
    let getClassName = GlobalStyle().labelHeader;
    if (props.className != null) getClassName = props.className;
    return (
        <AppLabel
            label={props.label}
            style={props.style}
            dir={props.dir}
            hidden={props.hidden}
            className={getClassName}
        />
    );
};

export default AppLabelHeader;
