import {makeStyles} from "@material-ui/core";
import {GlobalConstants} from "../../Utilities/GlobalConstants";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: GlobalConstants.ContentMarginTopFromAppbar,
        paddingBottom: 60,
    },
    ListItemStyles: {
        textAlign: "right!important" as any,
        border: "1.5px solid #00897B",
        borderColor: GlobalConstants.PrimaryColor,
        borderRadius: "10px",
        margin: "8px 0",
    },
    badgeStyles: {
        background: "#f72516",
        color: "#ffffff",
        padding: 5,
        borderRadius: "10px",
    },

    btnAccept: {
        backgroundColor: GlobalConstants.GreenColor,
        color: GlobalConstants.TextSecondaryColor,
        padding: "5px",
        borderRadius: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "15px",
        "&:hover": {
            backgroundColor: GlobalConstants.GreenColor,
            borderRadius: "5px",
            boxShadow: "none",
        },
    },
    btnDenied: {
        backgroundColor: GlobalConstants.RedColor,
        color: GlobalConstants.TextSecondaryColor,
        padding: "5px",
        borderRadius: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: "15px",
        "&:hover": {
            backgroundColor: GlobalConstants.RedColor,
            borderRadius: "5px",
            boxShadow: "none",
        },
    },
}));
export default useStyles;
