import {Grid} from "@material-ui/core";
import React, {ReactNode} from "react";
import {GridSize} from "@material-ui/core/Grid/Grid";

const AppGridItem = (props: {
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    all?: GridSize;
    padding?: "";
    style?: React.CSSProperties;
    className?: string;
    hidden?: boolean;
    children: ReactNode;
}) => {
    //const classes = useStyles();
    let getXs = props.xs ? props.xs : 12;
    let getSm = props.sm ? props.sm : 6;
    let getMd = props.md ? props.md : 4;
    let getLg = props.lg ? props.lg : 3;

    if (props.all != null) {
        getXs = props.all;
        getSm = props.all;
        getMd = props.all;
        getLg = props.all;
    }
    let GetGridItemStyle: React.CSSProperties = {
        alignContent: "center",
        padding: "10px",
    };
    if (props.style != null) GetGridItemStyle = props.style;
    return (
        <Grid
            className={props.className}
            style={GetGridItemStyle}
            item
            xs={getXs}
            sm={getSm}
            md={getMd}
            lg={getLg}
            hidden={props.hidden}
            children={props.children}
        />
    );
};

export default AppGridItem;
