import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: GlobalConstants.ContentMarginTopFromAppbar,
  },
  input: {
    background: "#ffffff",
    width: "80vw",
  },
  btnSend: {
    color: "#fff",
    backgroundColor: "#ff8f00",
    padding: "10px",
    borderRadius: "20px",
    width: "50vw",
    "&:hover": {
      backgroundColor: "#ff8f00",
    },
  },
}));
export default useStyles;
