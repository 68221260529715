import { Component, ErrorInfo, ReactNode } from "react";
import Remote from "../../httpService/Remote";
import ApiService from "../../httpService/ApiService";
import IApiResult from "../../Models/IApiResult";
import { Button, Container, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Routing from "./../../Routing/routing";
import { AppMessages, ErrorMessages } from "../../ApplicationMessages/AppMessages";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error.message);
    // You can also log the error to an error reporting service
    this.LogError(error, errorInfo);
  }

  private LogError(error: Error, errorInfo: ErrorInfo) {
    Remote.call(
      ApiService.logError(
        error.message,
        error.name,
        error.stack,
        errorInfo.componentStack
      ),
      (response: IApiResult) => {
        if (response.isSuccess) {
        } else {
        }
      },
      (onProgress: boolean) => { },
      (error: string) => { }
    );
  }

  render() {
    if (this.state.hasError) {
      return <Fallback />;
      // window.location.href = Routing.Main;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;

const Fallback = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(Routing.Login);
    window.location.reload();
  };

  return (
    <Container>
      <Typography variant="h3">{ErrorMessages.ErrorOcurred}</Typography>
      <br />
      <Button variant="contained" color="secondary" onClick={handleClick}>
        {AppMessages.Back}
      </Button>
    </Container>
  );
};
