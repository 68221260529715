import JwtToken from "../Models/AppModels/JwtToken";
import { localStorageHelper } from "./Helpers/localStorageHelper";
import { User } from "../Models/AppModels/User";
import { GlobalConstants } from "./GlobalConstants";

export class UserManager {
  private static userManager: UserManager | null = null;
  private Token = "Token";
  private RefreshToken = "RefreshToken";
  private Mobile = "Mobile";
  private Name = "Name";
  private Family = "Family";

  static get() {
    if (this.userManager == null) {
      this.userManager = new UserManager();
    }
    return this.userManager;
  }

  isToken(): Boolean {
    return (
      this.loadToken() != null &&
      this.loadToken().token != null &&
      this.loadToken().refreshToken != null
    );
  }

  loadToken(): JwtToken {
    return {
      token: localStorageHelper.load(this.Token),
      refreshToken: localStorageHelper.load(this.RefreshToken),
    };
  }

  saveToken(jwtToken: JwtToken) {
    localStorageHelper.store(this.Token, jwtToken.token);
    localStorageHelper.store(this.RefreshToken, jwtToken.refreshToken);
  }

  deleteToken() {
    localStorageHelper.remove(this.Token);
    localStorageHelper.remove(this.RefreshToken);
  }

  deleteUserData() {
    this.deleteToken();
    localStorageHelper.remove(this.Mobile);
    localStorageHelper.remove(this.Name);
    localStorageHelper.remove(this.Family);
    localStorageHelper.remove(GlobalConstants.UserFcmTopicLocalStorageKey);
  }

  loadUser(): User {
    return User.newUser(
      localStorageHelper.load(this.Mobile),
      localStorageHelper.load(this.Name),
      localStorageHelper.load(this.Family)
    );
  }

  saveUser(user: User) {
    localStorageHelper.store(this.Mobile, user.mobile);
    localStorageHelper.store(this.Name, user.name);
    localStorageHelper.store(this.Family, user.family);
  }
}
