import React, {UIEvent, useEffect, useState} from "react";
import AppContainer from "../core/AppContainer";
import useStyles from "./chatItem.styles";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import Routing from "../../Routing/routing";
import {Box, Paper, TextField} from "@material-ui/core";
import {MessageLeft, MessageRight} from "./Message/message.component";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import IApiResultData from "../../Models/IApiResultData";
import TicketMessage from "../../Models/AppModels/TicketMessage";
import {Toast} from "../../Utilities/Toast";
import {TicketStatusEnum} from "../../Models/Enums/TicketStatus";
import {GetTicketMessageResponse} from "../../Models/AppModels/GetTicketMessageResponse";
import {GlobalConstants} from "../../Utilities/GlobalConstants";
import {ErrorMessages} from "../../ApplicationMessages/AppMessages";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import AppButtonIcon from "../core/App-Button/App-ButtonIcon";
import {SendRounded} from "@mui/icons-material";

const ChatItem = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<TicketMessage[]>([]);
    const [message, setMessage] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [hasMoreData, setHasMoreData] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    let ticketItemId = 0;
    let icon = "";
    let title = "";

    useEffect(() => {
        GetMessagesFromServer(page);
    }, []);

    if (!location.state) {
        return <Navigate to={Routing.Chat} replace/>;
    }

    ticketItemId = location.state.id;
    icon = location.state.icon;
    title = location.state.title;

    if (ticketItemId === 0) {
        return <Navigate to={Routing.Chat} replace/>;
    }

    const handleGoBackToList = () => {
        navigate(Routing.Main + "/" + Routing.Chat, {replace: true});
    };

    const GetMessagesFromServer = (page: number) => {
        if (page === 1 || hasMoreData) {
            Remote.call(
                ApiService.getTicketMessages(
                    ticketItemId,
                    page,
                    GlobalConstants.PageSizeChatMessage
                ),
                (response: IApiResultData<GetTicketMessageResponse>) => {
                    if (response.data) {
                        if (response.data.totalPages > page) {
                            setHasMoreData(true);
                        } else {
                            setHasMoreData(false);
                        }

                        if (page === 1) {
                            setMessages([...response.data.messages]);
                        } else {
                            setMessages((prev) => [...prev, ...response.data.messages]);
                        }
                    }
                },
                (onProgress: boolean) => {
                    setIsLoading(onProgress);
                },
                (error: string) => {
                    Toast.show(error);
                }
            );
        }
    };

    const handleMessageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const value: string = event.target.value;
        setMessage(value);
    };

    const handleSendClick = () => {
        if (!message) {
            Toast.show(ErrorMessages.RequiredMessageText);
            return;
        }

        Remote.call(
            ApiService.createNewTicketMessage({
                TicketItemId: ticketItemId,
                Title: message,
            }),
            (response: IApiResultData<boolean>) => {
                if (response.data) {
                    setMessage("");
                    setPage(1);
                    GetMessagesFromServer(1);
                } else {
                    Toast.show(response.message);
                }
            },
            (onProgress: boolean) => {
                setIsLoading(onProgress);
            },
            (error: string) => {
                Toast.show(error);
            }
        );
    };

    const handleScroll = (event: UIEvent<HTMLElement>) => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        const diff = Math.abs(scrollHeight + scrollTop - clientHeight);
        if (diff >= 0 && diff <= 5 && hasMoreData) {
            setPage((prev) => prev + 1);
            GetMessagesFromServer(page);
        }
    };

    return (
        <AppContainer isLoadingSpinner={isLoading}>
            <AppToolbar title={title} src={icon} onClickBack={handleGoBackToList}/>
            <Box style={{width: "100%", height: "100%",backgroundColor:"#ffffff"}}>
                <div className={classes.container}>
                    <div className={classes.divMain}>
                        <Paper
                            elevation={0}
                            className={classes.messagesBody}
                            onScroll={handleScroll}
                        >
                            {messages.map((messageItem, index) => {
                                if (messageItem.ticketStatus === TicketStatusEnum.UserAnswer) {
                                    return (
                                        <MessageRight
                                            key={index}
                                            seen={messageItem.seenUser}
                                            message={messageItem.title}
                                            timestamp={`${messageItem.time} ${messageItem.date}`}
                                        />
                                    );
                                } else {
                                    return (
                                        <MessageLeft
                                            key={index}
                                            message={messageItem.title}
                                            timestamp={`${messageItem.time} ${messageItem.date}`}
                                        />
                                    );
                                }
                            })}
                        </Paper>
                    </div>
                </div>
                <Box style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0
                }}>
                    <div style={{
                        width: "100%",
                        paddingBottom: 10,
                    }}>
                        <form className={classes.wrapForm} noValidate autoComplete="off">
                            <TextField dir="rtl" value={message} variant="outlined" id="standard-text"
                                       size="small" multiline maxRows={3} onChange={handleMessageChange}
                                       InputProps={{classes: {notchedOutline: classes.notchedOutline}}}
                                       className={classes.wrapText}
                            />
                            &nbsp;
                            <AppButtonIcon
                                style={{width: 40, height: 40, alignContent: "center", textAlign: "center"}}
                                onClick={handleSendClick}
                                icon={<SendRounded style={{width: "70%", height: "80%"}}/>}
                            />
                        </form>
                    </div>
                </Box>
            </Box>
        </AppContainer>
    );
};

export default ChatItem;
