import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../../Utilities/GlobalConstants";

export const useStylesGeneral = makeStyles((theme) => ({
  BottomNavigation: {
    backgroundColor: GlobalConstants.PrimaryColor,
    borderTopRightRadius:GlobalConstants.headerRadius,
    borderTopLeftRadius:GlobalConstants.headerRadius,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "60px",
  },
  BottomNavigationAction: {
    color: "#fafcff",
  },
  BottomNavigationActionLabel: {
    fontSize: "100%",
    color: "#fafcff",
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
  },
  AppBar: {
    backgroundColor: GlobalConstants.PrimaryColor,
    justifyContent: "space-between",
  },
  list: {
    width: 220,
  },
  ListItemStyles: {
    textAlign: "right!important" as any,
  },
  divDrawerHeader: {
    backgroundColor: GlobalConstants.PrimaryColor,
    height: "20vh",
    borderBottomLeftRadius: "25%",
    borderBottomRightRadius: "25%",
  },
  drawerHeaderTypographyColor: {
    color: "#ffffff",
  },
  ListItemTextWhite: {
    color: "#ffffff",
  },
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fafcff",
    [theme.breakpoints.down("xs")]: {
      minWidth: "0",
    },
  },
  selected: {
    color: "#d47f00!important",
  },
  label: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "70%",
    },
  },
}));
