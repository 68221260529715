import { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import useStyles from "./pdf-view.styles";
// @ts-ignore
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import { useNavigate, useLocation } from "react-router-dom";

const PdfView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [link, setLink] = useState<string>("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { height, width } = useWindowDimensions();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    setLink(location.state.link);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const renderMobilePdfView = (
    <div>
      <Document
        className={classes.pdfView}
        error={AppMessages.ErrorLoadingData}
        loading={AppMessages.LoadingData}
        file={link}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            width={width}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </div>
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.mainContainer}>
      <Container>
        <AppToolbar
          title={""}
          onClickBack={handleGoBack}
        />
        <div className={classes.divDownload}>
          <p>
            <a className={classes.downloadLink} href={link}>
              {AppMessages.ClickDownloadPdf}
            </a>
          </p>
        </div>
        <br />
        {renderMobilePdfView}
      </Container>
    </div>
  );
};

export default PdfView;
