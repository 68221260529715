import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { JobTypeEnum } from "../../Models/Enums/JobTypeEnum";

const JobTypeList = [
  { id: 0, value: AppMessages.Choose },
  { id: JobTypeEnum.Employed, value: AppMessages.Employed },
  { id: JobTypeEnum.Retired, value: AppMessages.Retired },
];

export default JobTypeList;
