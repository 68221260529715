export enum TransactionType {
  //واریز
  Settle = 0,
  //برداشت
  Withdrawal = 1,
}

export const TransactionTypeList = [
  { id: TransactionType.Settle, value: "واریز" },
  { id: TransactionType.Withdrawal, value: "برداشت" },
];

export const getTransactionTypeLabel = (type: TransactionType): string => {
  var filteredItems = TransactionTypeList.filter((x) => x.id === type);
  return filteredItems[0].value;
};
