import { MenuItem, Select } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { Typography } from "@mui/material";
import * as React from "react";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import useStyles from "./App-Select.styles";

const AppSelect = (props: {
  id?: string;
  label?: string;
  style?: React.CSSProperties;
  inputProps?: StandardInputProps["inputProps"];
  dir?: string;
  data?: any;
  size?: Number;
  disable?: boolean;
  hidden?: boolean;
  defaultValue?: Number;
  className?: string;
  onChange?: SelectInputProps["onChange"];
}) => {
  const classes = useStyles();
  if (props.hidden) return <div />;
  //init list item
  let list = props.data ? props.data : [];
  let listItem = [];
  for (let index = 0; index < list.length; index++) {
    const element = list[index];
    listItem.push(
      <MenuItem key={element.id} value={element.id}>
        {element.value}
      </MenuItem>
    );
  }
  let getDefaultValue = 0;
  if (list.length > 0) getDefaultValue = list[0].id;
  return (
    <div className={props.className}>
      <div className={classes.div}>
        <div className={classes.divLabel}>
          <Typography className={classes.label}>{props.label}</Typography>
        </div>
        <div>
          <Select
            fullWidth={true}
            required
            style={props.style}
            className={classes.view}
            id={props.id}
            dir={props.dir ? props.dir : "rtl"}
            value={props.defaultValue ? props.defaultValue : getDefaultValue}
            onChange={props.onChange}
            inputProps={props.inputProps}
            disabled={props.disable}
            variant="outlined"
          >
            {listItem}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default AppSelect;
