import React from "react";

import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider, MobileDatePicker,} from "@mui/x-date-pickers-pro";
import useStyles from "./App-Date-Picker.styles";
import {Typography} from "@mui/material";
import AppTextField from "../TextField/App-Text_field";
import AdapterJalali from "@date-io/date-fns-jalali";
import moment from "jalali-moment";

const AppDatePicker = (props: {
    id?: string;
    label?: string;
    disable?: boolean;
    hidden?: boolean;
    className?: string;
    value?: string|null;
    onChange?: (data: any) => void;
}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState<Dayjs | null>(null);
    if (props.hidden) return <div/>;
    const onAccept = (newValue: Dayjs | null) => {
        const date = dayjs(newValue).format("YYYY/MM/DD");
        let SelectDay = new Date(date).toLocaleDateString("fa-IR");
        if (props.onChange != null) props.onChange(SelectDay);
    };
    if(props.value != null && value == null) {
        let defValue= moment
                .from(props.value, 'fa', 'YYYY/MM/DD')
                .format('YYYY/MM/DD');
        setValue(dayjs(defValue))
    }
    return (
        <div className={classes.div}>
            <div className={classes.divLabel}>
                <Typography className={classes.label}>{props.label}</Typography>
            </div>
            <div>
                <LocalizationProvider dateAdapter={AdapterJalali}>
                    <MobileDatePicker
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        onAccept={onAccept}
                        renderInput={(params: any) => (
                            <AppTextField defaultValue={props.value} {...params} />
                        )}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
};

export default AppDatePicker;
