import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../Utilities/GlobalConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url("/Images/bg_background.png")`,
    backgroundColor: GlobalConstants.PrimaryColor,
    height: "100vh",
  },
  centerItems: {
    alignItems: "center",
  },
  HeaderIcons: {
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "6%",
    },
  },
  HeaderIconsImageSize: {
    width: "23%",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "38%",
    },
  },
  bottomSection: {
    paddingBottom:"15px",
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    color: GlobalConstants.TextSecondaryColor,
    left: 0,
    right: 0,
    [theme.breakpoints.down("xs")]: {
      height: "5%",
      fontSize: "9px",
    },
  },
  textVersion: {
    textAlign: "center",
    color: GlobalConstants.TextSecondaryColor,
    left: 0,
    right: 0,
    margin: 7,
    fontSize: "10px",
  },
}));
export default useStyles;
