import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
const useStyles = makeStyles((theme) => ({
  container: {},
  containerHeader: {
    borderBottomRightRadius: "50px",
    borderBottomLeftRadius: "50px",
    backgroundImage: `url("/Images/bg_background.png")`,
    backgroundColor: GlobalConstants.PrimaryColor,
  },
  centerItems: {
    alignItems: "center",
  },
  HeaderIcons: {
    alignItems: "center",
    textAlign: "center",
    marginTop: GlobalConstants.headerRadius,
    marginBottom: GlobalConstants.headerRadius,
  },
  HeaderIconsImageSize: {
    width: "40%",
  },
  mainPart: {
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    marginTop: "5px",
  },
  txtFieldGrid: {
    textAlign: "center",
    alignContent: "center",
  },
  btnConfirmGrid: {
    marginTop: "20px",
  },
  title: {
    alignItems: "center",
    color: GlobalConstants.PrimaryColor,
    fontSize: "20px",
    fontWeight: "bold",
  },
  important: {
    width: "100%",
    color: "red",
    textAlign: "center",
    marginTop: "30px"
  },
}));
export default useStyles;
