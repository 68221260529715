import {makeStyles} from "@material-ui/core";
import {GlobalConstants} from "../../../Utilities/GlobalConstants";

const useStyles = makeStyles((theme) => ({
  title: {
    alignItems: "center",
    color: GlobalConstants.TextSecondaryColor,
    fontSize: "16px",
    fontWeight: "bold",
  },
  titleOutline: {
    alignItems: "center",
    color: GlobalConstants.PrimaryColor,
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
export default useStyles;
