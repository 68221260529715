import { useNavigate } from "react-router-dom";
import AppContainer from "../core/AppContainer";
import { Container, Grid } from "@material-ui/core";
import Routing from "../../Routing/routing";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import React, { useEffect, useState } from "react";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import useStyles from "./PaymentResult.styles";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import AppLabelHeader from "../core/Label/App-Label-Header";
import AppButton from "../core/App-Button/App-Button";
import AppLabelTitle from "../core/Label/App-Label-Title";

const PaymentResult = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [trackId, setTrackId] = useState<string | null>(null);
  const [transactionId, setTransactionId] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>("");

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    setTrackId(params.get("TrackId"));
    setTransactionId(params.get("TransactionId"));
    setSuccess(params.get("Success") === "True");
    setMessage(params.get("Message"));

    if (
      success === undefined ||
      transactionId === undefined ||
      trackId === undefined
    ) {
      setSuccess(false);
    }
  }, [success, trackId, transactionId]);

  const onClickSubmit = () => {
    navigate(Routing.Main);
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <Container className={classes.container}>
        <Grid
          className={classes.centerItems}
          direction="column"
          alignItems="center"
          justifyContent="center"
          container
        >
          <div
            className={classes.paymentBox}
            style={{
              borderColor: success
                ? GlobalConstants.GreenColor
                : GlobalConstants.RedColor,
            }}
          >
            <AppLabelHeader
              label={GlobalConstants.AppName}
              style={{
                textAlign: "center",
              }}
            />
            <Grid item xs={12} md={12}>
              <CheckCircleOutlineRounded
                style={{
                  width: "80px",
                  height: "80px",
                  marginTop: "20px",
                  color: success
                    ? GlobalConstants.GreenColor
                    : GlobalConstants.RedColor,
                }}
              />
            </Grid>
            <AppLabelHeader
              label={message ? message : ""}
              style={{
                textAlign: "center",
                color: success
                  ? GlobalConstants.GreenColor
                  : GlobalConstants.RedColor,
              }}
            />
            <AppLabelTitle
              hidden={!success}
              label={`${AppMessages.trackId} : ${trackId ? trackId : ""}`}
              style={{
                marginTop: "10px",
                textAlign: "center",
              }}
            />
            <AppLabelTitle
              hidden={!success}
              label={`${AppMessages.transactionId} : ${
                transactionId ? transactionId : ""
              }`}
              style={{
                marginTop: "2px",
                textAlign: "center",
              }}
            />
          </div>
          <AppButton
            type="button"
            label={AppMessages.backToApp}
            className={classes.btnOutline}
            onClick={onClickSubmit}
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          />
        </Grid>
      </Container>
    </AppContainer>
  );
};

export default PaymentResult;
