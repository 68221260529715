export const sessionStorageHelper = {
  load(key: string) {
    const stored = sessionStorage.getItem(key);
    return stored == null ? undefined : JSON.parse(stored);
  },
  store(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  modify(key: string, value: any) {
    this.store(key, value);
  },
  remove: (key: string) => {
    sessionStorage.removeItem(key);
  },
  clearSessionStorage: () => {
    sessionStorage.clear();
  },
};
