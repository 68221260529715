import React, { useEffect, useState } from "react";
import useStyles from "./VerifyOtp.styles";
import { Grid, Typography } from "@material-ui/core";
import { AccessTime } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  AppMessages,
  ErrorMessages,
} from "../../ApplicationMessages/AppMessages";
import { Toast } from "../../Utilities/Toast";
import { UserManager } from "../../Utilities/UserManager";
import JwtToken from "../../Models/AppModels/JwtToken";
import { User } from "../../Models/AppModels/User";
import IApiResultData from "../../Models/IApiResultData";
import AppContainer from "../core/AppContainer";
import Routing from "../../Routing/routing";
import { IVerifyOtpProps } from "./IVerifyOtpProps";
import ConfirmCode from "../../Models/AppModels/ConfirmCode";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import { DeviceInfoHelper } from "../../Utilities/Helpers/DeviceInfoHelper";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AppTextField from "../core/TextField/App-Text_field";
import AppButton from "../core/App-Button/App-Button";
import AppGridItem from "../core/Grid/App-Grid-Item";
import AlertDialog from "../core/dialog/AlertDialog";

const VerifyOtp = ({ phoneNumber }: IVerifyOtpProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmCode, setConfirmCode] = useState<string>("");
  const [remainingSeconds, setRemainingSeconds] = useState<number>(
    GlobalConstants.OtpResendRemainingTime
  );
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDialogMsg, setShowDialogMsg] = useState<string>("");

  useEffect(() => {
    if (remainingSeconds <= 0) {
      return;
    }

    const id = setInterval(() => {
      setRemainingSeconds(remainingSeconds - 1);
    }, 1000);

    return () => clearInterval(id);
  }, [remainingSeconds]);

  const handleConfirmCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value: string = event.target.value;
    setConfirmCode(value);
  };

  const onClickSubmit = () => {
    if (!confirmCode) {
      Toast.show(ErrorMessages.RequiredConfirmCode);
      return;
    }
    if (confirmCode.length < GlobalConstants.ConfirmCodeLength) {
      Toast.show(ErrorMessages.InvalidConfirmCodeLength);
      return;
    }
    CallVerifyOtpApi();
  };

  const CallVerifyOtpApi = () => {
    const model: ConfirmCode = {
      ConfirmCode: confirmCode,
      Mobile: phoneNumber,
      Device: DeviceInfoHelper.getDevice(),
      DeviceId: "",
      FcmToken: "",
    };
    Remote.call(
      ApiService.confirmCode(model),
      (response: IApiResultData<JwtToken>) => {
        if (response.data) {
          UserManager.get().saveToken(response.data);
          CallGetUserInfo();
        } else {
          setShowDialogMsg(response.message);
          setShowDialog(true);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  function CallGetUserInfo() {
    Remote.call(
      ApiService.getUserInfo(),
      (response: IApiResultData<User>) => {
        if (response.isSuccess && response.data) {
          UserManager.get().saveUser(response.data);
          navigate(Routing.Main);
        } else {
          Toast.show(response.message);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  }

  const resendConfirmCode = () => {
    Remote.call(
      ApiService.resendConfirmCode(phoneNumber),
      (response: IApiResultData<boolean>) => {
        if (response.data) {
          setRemainingSeconds(GlobalConstants.OtpResendRemainingTime);
        } else {
          Toast.show(response.message);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const remainingTimeElement = (
    <>
      <span className={classes.remainingSeconds}>
        {remainingSeconds > 0 ? (
          <span>
            {remainingSeconds} {AppMessages.Second}
          </span>
        ) : (
          <span style={{ cursor: "pointer" }} onClick={resendConfirmCode}>
            {AppMessages.Resend}
          </span>
        )}
      </span>
    </>
  );

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AlertDialog
        title={GlobalConstants.AppName}
        message={showDialogMsg}
        open={showDialog}
        positive={AppMessages.accept}
        onChange={setShowDialog}
        onResponse={(res: any) => {
          navigate(Routing.Splash);
        }}
      />
      <Grid container className={GlobalStyle().main}>
        <AppGridItem all={12}>
          <AppTextField
            id="confirmCode"
            inputProps={{
              style: { textAlign: "center" },
              maxLength: 4,
            }}
            label={AppMessages.EnterCode}
            className={classes.divTextField}
            required={true}
            onChange={handleConfirmCodeChange}
          />
        </AppGridItem>
        <AppGridItem all={12}>
          <Typography
            className={classes.TypoRemaining}
            variant="body1"
            color="initial"
          >
            <AccessTime />
            {AppMessages.OtpResendRemainingTime}
            <span>&nbsp;</span>
            {remainingTimeElement}
          </Typography>
        </AppGridItem>
        <AppGridItem all={12}>
          <AppButton
            type="button"
            className={GlobalStyle().btnAuth}
            style={{ marginTop: "5px" }}
            label={AppMessages.accept}
            onClick={onClickSubmit}
          />
        </AppGridItem>
      </Grid>
    </AppContainer>
  );
};

export default VerifyOtp;
