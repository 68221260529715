import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import Remote from "../../httpService/Remote";
import ApiService from "../../httpService/ApiService";
import IApiResultData from "../../Models/IApiResultData";
import AppContainer from "../core/AppContainer";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../Utilities/Toast";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import useStyles from "./GuarantorRequestLoan.styles";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import Routing from "../../Routing/routing";
import AppButton from "../core/App-Button/App-Button";
import AppLabelContent from "../core/Label/App-Label-Content";
import AppTextField from "../core/TextField/App-Text_field";
import AppLabelHeader from "../core/Label/App-Label-Header";
import AlertDialog from "../core/dialog/AlertDialog";
import { Validation } from "../../Utilities/Validators/Validation";
import ResponseCurrentLoanRequestDto from "../../Models/AppModels/Loan/ResponseCurrentLoanRequestDto";
import { LoanRequestStatusType } from "../../Models/Enums/LoanRequestStatusType";
import IApiResult from "../../Models/IApiResult";

const GuarantorRequestLoan = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [guarantorNationalCode, setGuarantorNationalCode] =
    useState<string>("");

  const handleGoBack = () => {
    getUserCurrentLoanRequest();
  };

  const getUserCurrentLoanRequest = () => {
    Remote.call(
      ApiService.getUserCurrentLoanRequest(),
      (response: IApiResultData<ResponseCurrentLoanRequestDto>) => {
        if (response.data && response.data.loanRequestStatus) {
          const status = response.data.loanRequestStatus;
          if (status <= LoanRequestStatusType.MaxLoanRequest) {
            navigate(Routing.RequestLoan);
          } else if (status <= LoanRequestStatusType.GuarantorIntroduction) {
            navigate(Routing.Main);
          }
        } else {
          navigate(Routing.RequestLoan);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const nationalCodeChange = (event: any): void => {
    setGuarantorNationalCode(event.target.value);
  };

  const onClickNext = () => {
    if (!Validation.isNationalCode(guarantorNationalCode)) {
      Toast.show("کد ملی را صحیح وارد نمایید");
      return;
    }
    Remote.call(
      ApiService.addGuarantor(guarantorNationalCode),
      (response: IApiResult) => {
        if (response.isSuccess) {
          setShowDialog(true);
        } else {
          Toast.show(response.message);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const dialogResponse = (res: any) => {
    navigate(Routing.Main);
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AppToolbar
        title={AppMessages.guarantorIntroduction}
        onClickBack={handleGoBack}
      />
      <AlertDialog
        message={AppMessages.initialRequestRegistration}
        open={showDialog}
        positive={AppMessages.accept}
        onChange={setShowDialog}
        onResponse={dialogResponse}
      />

      <Box style={{ width: "100%" }} className={classes.container}>
        <Grid container className={GlobalStyle().main}>
          <AppGridItem all={12} style={{ paddingTop: "10px" }}>
            <AppLabelHeader
              className={classes.HeaderLoan}
              label={AppMessages.titleGuarantorIntroduction}
            />
          </AppGridItem>
          <br />
          <br />
          <br />
          <AppGridItem
            all={12}
            style={{ paddingTop: "10px", textAlign: "center" }}
          >
            <AppTextField
              id="nationalCode"
              value={guarantorNationalCode}
              onChange={nationalCodeChange}
              className={classes.divTextField}
              inputProps={{
                maxLength: 10,
              }}
              label={`${AppMessages.NationalCode} ${AppMessages.guarantor}`}
            />
          </AppGridItem>

          <AppLabelContent
            style={{ textAlign: "center", margin: "20px", color: "red" }}
            label={AppMessages.hintGuarantorIntroduction1}
          />
          <AppLabelContent
            style={{ textAlign: "center", margin: "20px", color: "red" }}
            label={AppMessages.hintGuarantorIntroduction2}
          />
          <AppGridItem
            all={12}
            style={{
              bottom: 0,
              left: 0,
              right: 0,
              marginBottom: 20,
              position: "fixed",
              textAlign: "center",
              paddingTop: "50px",
            }}
          >
            <AppButton
              type="button"
              label={AppMessages.AcceptContinue}
              onClick={onClickNext}
            />
          </AppGridItem>
        </Grid>
      </Box>
    </AppContainer>
  );
};

export default GuarantorRequestLoan;
