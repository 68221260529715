export const ErrorMessages = {
  TitleRequired: "عنوان الزامی است",
  DescriptionRequired: "توضیحات الزامی است",
  IncorrectPhoneNumber: "شماره موبایل صحیح نمیباشد",
  IncorrectNationalCode: "کد ملی صحیح نمیباشد",
  RequiredConfirmCode: "کد تایید را وارد نمایید",
  InvalidName: "نام را صحیح وارد نمایید",
  InvalidFamily: "نام خانوادگی را صحیح وارد نمایید",
  InvalidConfirmCodeLength: "تعداد ارقام کد صحیح نمیباشد",
  ErrorOcurred: "خطایی رخ داده است",
  ErrorConnection: "خطا در برقراری ارتباط با سرور",
  ErrorNotFound: "خطا در اطلاعات دریافتی، سعی مجدد",
  ErrorRefreshToken: "خطا در احراز هویت، سعی مجدد",
  RequiredMessageText: "متن پیام الزامی است",
};

export const AppMessages = {
  MainWebsite: "وب سایت",
  Name: "نام",
  Family: "نام خانوادگی",
  SendIdeaPageDescription:
    "از این طریق میتوانید ایده خود را در حوزه نظام مدیریت مالی  بخش عمومی به خزانه داری کل کشور ارسال نمایید",
  OtpResendRemainingTime: "مدت زمان دریافت کد",
  phoneNumber: "شماره تلفن همراه",
  NationalCode: "کد ملی",
  LegalCode: "كد حقوقی",
  FatherName: "نام پدر",
  IdCode: "شماره شناسنامه",
  BirthDate: "تاریخ تولد",
  PhoneNumberHome: "شماره  تلفن منزل",
  PhoneNumberWork: "شماره تلفن محل کار",
  Email: "ایمیل",
  JobLocation: "محل شغل",
  AccountNumber: "شماره حساب",
  EnterCode: "کد را وارد نمایید",
  accept: "تایید",
  denied: "عدم تایید",
  AcceptContinue: "تایید و ادامه",
  Cancel: "انصراف",
  Send: "ارسال",
  Back: "بازگشت",
  Resend: "ارسال مجدد",
  SamplePhoneNumber: "09xxxxxxxxx",
  Home: "خانه",
  Title: "عنوان",
  Mobile: "موبایل",
  DescriptionText: "متن توضیحات",
  CommunicationBox: "صندوق ارتباطی",
  SendIdeas: "ارسال ایده",
  Search: "جستجو",
  Calculator: "ماشین حساب",
  Help: "راهنما",
  AboutSokhan: "درباره ما",
  Second: "ثانیه",
  Messages: "پیام ها",
  ShareApplication: "اشتراک برنامه",
  SearchOnTitle: "جستجو در عنوان",
  SearchOnDescription: "جستجو در متن",
  SearchOnAll: "جستجو در همه",
  NewMessage: "پیام جدید",
  ExitAccount: "خروج از حساب کاربری",
  EnterAccount: "ورود به حساب",
  SplashDialogHint:
    "*در صورت انتخاب دکمه ورود به حساب از حساب کاربری خود خارج و وارد صفحه ورود میشوید",
  TryAgain: "سعی مجدد",
  Exit: "خروج",
  Account: "حساب کاربری",
  WorkProvince: "استان محل کار",
  NoMoreData: "اطلاعات دیگری موجود نیست",
  LoadingData: "در حال بارگذاری ... ",
  ErrorLoadingData: "خطا هنگام بارگذاری",
  ClickDownloadPdf: "دانلود فایل Pdf",
  WantToExitAppQuestion: "آیا مایل به خروج از برنامه هستید ؟",
  AppVersion: "نسخه 1.0",
  AboutSokhanDescription: "صندوق اعتماد",
  AllRightsReserved:
    "کلیه حقوق مادی و معنوی اپلیکیشن متعلق به صندوق اعتماد میباشد",

  pensionFundType: "نوع صندوق بازنشستگی",
  employmentType: "نوع استخدام",
  jobType: "نوع شغل",
  Retired: "بازنشسته",
  Employed: "شاغل",
  Choose: "انتخاب کنید",

  RequestLoan: "درخواست وام",
  guarantee: "ضمانت ها",
  guarantor: "ضامن",
  myGuarantor: "ضامن من",
  guarantorIntroduction: "معرفی ضامن",

  loanNotFound: "وام تسویه نشده برای شما یافت نشد",
  totalAmount: "مبلغ کل",
  remainingAmount: "مبلغ باقیمانده",
  amountOfInstallment: "مبلغ هر قسط",
  loanData: "اطلاعات وام",
  amountLoanRequest: "مبلغ قابل درخواست وام",
  amountMaxLoanRequest: "حداکثر مبلغ درخواست وام",
  amountMinLoanRequest: "حداقل مبلغ درخواست وام",
  requestMaxLoan: "درخواست سقف وام",
  hintRequestMaxLoan:
    "نکته: در صورت درخواست سقف وام، باید مبلغ مابه التفاوت پرداخت گردد، بعد از پرداخت و تایید مدیر سیستم درخواست وام تایید می شود",
  differenceAmountMaxLoan: "مبلغ مابه التفاوت",
  titleGuarantorIntroduction: "برای درخواست وام نیاز به معرفی ضامن می باشد",
  hintGuarantorIntroduction1:
    "نکته : در صورت عدم تایید ضامن در خواست وام شما به حالت تعلیق در می آید و شما باید مجدد درخواست وام را ثبت نمایید",
  hintGuarantorIntroduction2:
    "نکته : هر فرد می تواند ضامن سه نفر به صورت همزمان باشد.",
  initialRequestRegistration:
    "ثبت درخواست اولیه انجام شد، بعد از تایید ضامن از طریق پیامک به شما اطلاع داده خواهد شد",
  changeGuarantorStatus: "آیا از تغییر وضعیت ضمانت اطمینان دارید",
  noData: "موردی یافت نشد",
  transactions: "تراکنش ها",

  trackId: "شماره پیگیری",
  transactionId: "شماره تراکنش",
  paymentSuccess: "پرداخت شما با موفقیت انجام شد",
  PaymentFailed: "پرداخت شما ناموفق بود",
  backToApp: "بازگشت به برنامه",
  FundInfo: "اطلاعات صندوق",
  dataNotFound: "اطلاعاتی یافت نشد",

  existingLoan: "وام جاری",
  availableAmount: "موجودی",
  amountMembership: "مبلغ حق عضویت",

  listRequestLoan: "لیست درخواستها",
  paymentInstallmentCurrentLoan: "پرداخت قسط وام جاری",
  amount: "مبلغ",
  description: "توضیحات",
  submit: "ثبت",
  status: "وضعیت",
};
