import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import useStyles from "./Register.styles";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import AppContainer from "../core/AppContainer";
import AppTextField from "../core/TextField/App-Text_field";
import PensionFundTypeList from "../../Utilities/Constant/PensionFundTypeList";
import AppSelect from "../core/Select/App-Select";
import { User } from "../../Models/AppModels/User";
import { Validation } from "../../Utilities/Validators/Validation";
import { Toast } from "../../Utilities/Toast";
import Remote from "../../httpService/Remote";
import ApiService from "../../httpService/ApiService";
import IApiResultData from "../../Models/IApiResultData";
import TicketItem from "../../Models/AppModels/TicketItem";
import AppButton from "../core/App-Button/App-Button";
import Routing from "../../Routing/routing";
import EmploymentTypeList from "../../Utilities/Constant/EmploymentTypeList";
import JobTypeList from "../../Utilities/Constant/JobTypeList";
import AppDatePicker from "../core/datepicker/App-Date-Picker";
import AppSelectGroup, {
  SelectGroupModel,
} from "../core/Select/App-Select-Group";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AlertDialog from "../core/dialog/AlertDialog";
import { JobTypeEnum } from "../../Models/Enums/JobTypeEnum";

// eslint-disable-next-line no-empty-pattern
const Register = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [nationalCode, setNationalCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [family, setFamily] = useState<string>("");
  const [legalCode, setLegalCode] = useState<string>("");
  const [fatherName, setFatherName] = useState<string>("");
  const [birthCertificateId, setBirthCertificateId] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [phoneNumberHome, setPhoneNumberHome] = useState<string>("");
  const [phoneNumberWork, setPhoneNumberWork] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [jobLocationList, setJobLocationList] = useState<SelectGroupModel[]>(
    []
  );

  const [pensionFundType, setPensionFundTypeChange] = useState<number>(0);
  const [employmentType, setEmploymentType] = useState<number>(0);
  const [jobType, setJobType] = useState<number>(0);
  const [jobLocation, setJobLocation] = useState<number>(0);
  //show
  const [showJobLocation, setShowJobLocation] = useState<boolean>(false);

  useEffect(() => {
    if (location.state) {
      setPhoneNumber(location.state.phoneNumber);
      setNationalCode(location.state.nationalCode);
    }
    callGetJobLocationList();
  }, []);

  const phoneNumberChange = (event: any): void => {
    setPhoneNumber(event.target.value);
  };
  const nationalCodeChange = (event: any): void => {
    setNationalCode(event.target.value);
  };
  const nameChange = (event: any): void => {
    setName(event.target.value);
  };
  const familyChange = (event: any): void => {
    setFamily(event.target.value);
  };
  const legalCodeChange = (event: any): void => {
    setLegalCode(event.target.value);
  };
  const fatherNameChange = (event: any): void => {
    setFatherName(event.target.value);
  };
  const birthCertificateIdChange = (event: any): void => {
    setBirthCertificateId(event.target.value);
  };
  const birthDateChange = (date: string): void => {
    setBirthDate(date);
  };
  const phoneNumberHomeChange = (event: any): void => {
    setPhoneNumberHome(event.target.value);
  };
  const phoneNumberWorkChange = (event: any): void => {
    setPhoneNumberWork(event.target.value);
  };
  const emailChange = (event: any): void => {
    setEmail(event.target.value);
  };

  const accountNumberChange = (event: any): void => {
    setAccountNumber(event.target.value);
  };

  const pensionFundTypeChange = (event: any): void => {
    setPensionFundTypeChange(event.target.value);
  };
  const employmentTypeChange = (event: any): void => {
    setEmploymentType(event.target.value);
  };
  const jobTypeChange = (event: any): void => {
    setJobType(event.target.value);
    if (event.target.value === JobTypeEnum.Employed) {
      setShowJobLocation(true);
    } else {
      setShowJobLocation(false);
      setJobLocation(0);
    }
  };
  const jobLocationChange = (event: any): void => {
    setJobLocation(event.target.value);
  };

  const onClickSubmit = () => {
    let user = new User();
    if (!Validation.isMobile(phoneNumber)) {
      toast("موبایل را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isNationalCode(nationalCode)) {
      toast("کد ملی را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(name)) {
      toast("نام را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(family)) {
      toast("نام خانوادگی را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(legalCode)) {
      toast("کد حقوقی را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(fatherName)) {
      toast("نام پدر را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(birthCertificateId)) {
      toast("شماره شناسنامه را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(birthDate)) {
      toast("تاریخ تولد را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(phoneNumberHome)) {
      toast("شماره تلفن خانه را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(phoneNumberWork)) {
      toast("شماره محل کار را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(email)) {
      toast("ایمیل را صحیح وارد نمایید");
      return;
    }

    if (!Validation.isValid(accountNumber)) {
      toast("شماره حساب را صحیح وارد نمایید");
      return;
    }

    if (pensionFundType === 0) {
      toast("نوع صندوق بازنشستگی را انتخاب نمایید");
      return;
    }
    if (employmentType === 0) {
      toast("نوع استخدام را انتخاب نمایید");
      return;
    }
    if (jobType === 0) {
      toast("نوع شغل را انتخاب نمایید");
      return;
    }
    user.mobile = phoneNumber;
    user.name = name;
    user.family = family;
    user.nationalCode = nationalCode;
    user.legalCode = legalCode;
    user.fatherName = fatherName;
    user.birthCertificateId = birthCertificateId;
    user.birthDate = birthDate;
    user.homePhoneNumber = phoneNumberHome;
    user.workPhoneNumber = phoneNumberWork;
    user.email = email;
    user.accountNumber = accountNumber;

    const getPensionFundType = PensionFundTypeList.find((element) => {
      return element.id === pensionFundType;
    });
    user.pensionFundType = getPensionFundType ? getPensionFundType.id : 0;

    const getEmploymentType = EmploymentTypeList.find((element) => {
      return element.id === employmentType;
    });
    user.employmentType = getEmploymentType ? getEmploymentType.id : 0;

    const getJobType = JobTypeList.find((element) => {
      return element.id === jobType;
    });
    user.jobType = getJobType ? getJobType.id : 0;

    user.jobLocation = jobLocation;

    CallRegisterApi(user);
  };

  const CallRegisterApi = (user: User) => {
    Remote.call(
      ApiService.register(user),
      (response: IApiResultData<TicketItem[]>) => {
        if (response.isSuccess) {
          setDialogMessage(response.message);
          setShowDialog(true);
        } else {
          toast(response.message);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        toast(error);
      }
    );
  };

  const callGetJobLocationList = () => {
    Remote.call(
      ApiService.getJobLocationList(),
      (response: IApiResultData<SelectGroupModel[]>) => {
        if (response.data) {
          setJobLocationList(response.data);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const toast = (msg: string) => {
    Toast.show(msg);
  };

  const dialogResponse = (res: any) => {
    navigate(Routing.Splash);
  };

  const registerElements = (
    <Box style={{ width: "100%" }}>
      <p className={classes.title}>ایجاد حساب کاربری</p>
      <Grid container className={GlobalStyle().main}>
        <AppGridItem>
          <AppTextField
            id="mobile"
            value={phoneNumber}
            dir="ltr"
            onChange={phoneNumberChange}
            inputProps={{
              maxLength: 11,
            }}
            label={AppMessages.phoneNumber}
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="nationalCode"
            value={nationalCode}
            onChange={nationalCodeChange}
            dir="ltr"
            inputProps={{
              maxLength: 10,
            }}
            label={AppMessages.NationalCode}
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="name"
            onChange={nameChange}
            label={AppMessages.Name}
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="family"
            onChange={familyChange}
            label={AppMessages.Family}
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="legalCode"
            onChange={legalCodeChange}
            dir="ltr"
            inputProps={{
              maxLength: 15,
            }}
            label={AppMessages.LegalCode}
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="fatherName"
            onChange={fatherNameChange}
            label={AppMessages.FatherName}
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="birthCertificateId"
            onChange={birthCertificateIdChange}
            label={AppMessages.IdCode}
            dir="ltr"
          />
        </AppGridItem>
        <AppGridItem>
          <AppDatePicker
            id="birthDate"
            onChange={birthDateChange}
            label={AppMessages.BirthDate}
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="phoneNumberHome"
            onChange={phoneNumberHomeChange}
            label={AppMessages.PhoneNumberHome}
            dir="ltr"
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="phoneNumberWork"
            onChange={phoneNumberWorkChange}
            label={AppMessages.PhoneNumberWork}
            dir="ltr"
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="accountNumber"
            onChange={accountNumberChange}
            label={AppMessages.AccountNumber}
            dir="ltr"
          />
        </AppGridItem>
        <AppGridItem>
          <AppTextField
            id="email"
            onChange={emailChange}
            label={AppMessages.Email}
            dir="ltr"
          />
        </AppGridItem>
        <AppGridItem>
          <AppSelect
            id="pensionFundType"
            label={AppMessages.pensionFundType}
            data={PensionFundTypeList}
            defaultValue={pensionFundType}
            onChange={pensionFundTypeChange}
          />
        </AppGridItem>
        <AppGridItem>
          <AppSelect
            id="employmentType"
            label={AppMessages.employmentType}
            data={EmploymentTypeList}
            defaultValue={employmentType}
            onChange={employmentTypeChange}
          />
        </AppGridItem>
        <AppGridItem>
          <AppSelect
            id="jobType"
            label={AppMessages.jobType}
            data={JobTypeList}
            defaultValue={jobType}
            onChange={jobTypeChange}
          />
        </AppGridItem>
        <AppGridItem>
          <AppSelectGroup
            id="jobLocation"
            onChange={jobLocationChange}
            defaultValue={jobLocation}
            hidden={!showJobLocation}
            data={jobLocationList}
            label={AppMessages.JobLocation}
          />
        </AppGridItem>
        <p className={classes.important}>تکمیل تمامی فیلد ها الزامی میباشد</p>
        <AppGridItem all={12}>
          <AppButton
            type="button"
            className={GlobalStyle().btnAuth}
            style={{ marginTop: "5px", marginBottom: "30px" }}
            label={AppMessages.accept}
            onClick={onClickSubmit}
          />
        </AppGridItem>
      </Grid>
    </Box>
  );

  if (!location.state) {
    return <Navigate to={Routing.Splash} />;
  }

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AlertDialog
        message={dialogMessage}
        open={showDialog}
        onChange={setShowDialog}
        onResponse={dialogResponse}
      />
      <Grid item className={classes.containerHeader}>
        <Grid
          className={classes.centerItems}
          direction="column"
          alignItems="center"
          justifyContent="center"
          container
        >
          <Grid item xs={12} md={12} className={classes.HeaderIcons}>
            <img
              alt=""
              className={classes.HeaderIconsImageSize}
              src="/Images/bg_splash_content.png"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.mainPart}>{registerElements}</div>
      </Grid>
    </AppContainer>
  );
};

export default Register;
