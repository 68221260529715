import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: GlobalConstants.ContentMarginTopFromAppbar,
  },
  content: {
    paddingTop: "15px",
    paddingBottom: "50px",
    paddingRight:"10px",
    paddingLeft:"10px",
  },
}));
export default useStyles;
