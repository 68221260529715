import React, { useEffect, useState } from "react";

import { Container, Grid } from "@material-ui/core";
import AppContainer from "../core/AppContainer";
import IApiResultData from "../../Models/IApiResultData";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import { useNavigate } from "react-router-dom";
import Routing from "./../../Routing/routing";
import { Toast } from "../../Utilities/Toast";
import AppGridItem from "../core/Grid/App-Grid-Item";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import useStyles from "./PaymentInstallment.styles";
import AppButton from "../core/App-Button/App-Button";
import AppTextField from "../core/TextField/App-Text_field";
import AppLabelDescription from "../core/Label/App-Label-Description";
import SendPaymentInstallment from "../../Models/AppModels/SendPaymentInstallment";
import ResponseCurrentLoanRequestDto from "../../Models/AppModels/Loan/ResponseCurrentLoanRequestDto";
import { LoanRequestStatusType } from "../../Models/Enums/LoanRequestStatusType";
import { NumberHelper } from "../../Utilities/NumberHelper";
import IApiResult from "../../Models/IApiResult";

const PaymentInstallment = () => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [canPay, setCanPay] = useState<boolean>(false);

  const [amount, setAmount] = useState<string>("0");
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    getUserCurrentLoanRequest();
  }, []);

  const getUserCurrentLoanRequest = () => {
    Remote.call(
      ApiService.getUserCurrentLoanRequest(),
      (response: IApiResultData<ResponseCurrentLoanRequestDto>) => {
        if (
          response.data &&
          response.data.loanRequestStatus === LoanRequestStatusType.Paid
        ) {
          if (response.data.monthlyInstallmentAmount > 0) {
            setAmount(
              NumberHelper.separateComma(response.data.monthlyInstallmentAmount)
            );
          }
          setCanPay(true);
        } else {
          Toast.show("وام قابل پرداخت ندارید");
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const handleGoBack = () => {
    navigate(Routing.Main);
  };

  const toast = (msg: string) => {
    Toast.show(msg);
  };

  const onClickSubmit = () => {
    if (!file) {
      toast("عکس رسید را انتخاب کنید");
      return;
    }

    const formData = new FormData();
    formData.append("description", description);
    formData.append("file", file ? file : "");

    const model: SendPaymentInstallment = {
      formData: formData,
    };

    Remote.call(
      ApiService.submitCurrentLoanInstallmentReceiptByUser(model),
      (response: IApiResult) => {
        Toast.show(response.message);
        if (response.isSuccess) {
          navigate(Routing.Main);
        }
      },
      (onProgress: boolean) => {
        setLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
      }
    }
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AppToolbar
        title={AppMessages.paymentInstallmentCurrentLoan}
        onClickBack={handleGoBack}
      />
      <br />
      <Container className={classes.container}>
        {canPay ? (
          <Grid container>
            <AppGridItem all={6}>
              <AppTextField
                id="amount"
                disable
                dir="ltr"
                value={amount}
                label="مبلغ قسط ماهانه"
              />
            </AppGridItem>

            <AppGridItem all={6}>
              <AppTextField
                id="description"
                dir="auto"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                label={AppMessages.description}
              />
            </AppGridItem>

            <AppGridItem all={12}>
              <AppLabelDescription label="بارگذاری عکس رسید" />
              <input type="file" onChange={handleFileChange} />
            </AppGridItem>

            <AppGridItem all={12}>
              <AppButton
                type="button"
                label={AppMessages.submit}
                style={{ marginTop: "30px", marginBottom: "30px" }}
                onClick={onClickSubmit}
              />
            </AppGridItem>
          </Grid>
        ) : (
          <p>امکان پرداخت وجود ندارد</p>
        )}
      </Container>
    </AppContainer>
  );
};

export default PaymentInstallment;
