import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import AppContainer from "../core/AppContainer";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import useStyles from "./sendReport.styles";
import {GlobalConstants} from "../../Utilities/GlobalConstants";
import {Button, Container, Grid, TextField, Typography,} from "@material-ui/core";
import {AppMessages, ErrorMessages,} from "../../ApplicationMessages/AppMessages";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import IApiResultData from "../../Models/IApiResultData";
import {Toast} from "../../Utilities/Toast";

const SendReport = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    let timer: ReturnType<typeof setTimeout>;

    useEffect(() => {
        return () => clearTimeout(timer);
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    };

    const clearInputs = () => {
        setTitle("");
        setDescription("");
    };

    const handleTitleChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const value: string = event.target.value;
        setTitle(value);
    };

    const handleDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const value: string = event.target.value;
        setDescription(value);
    };

    const sendReport = () => {
        if (!title) {
            Toast.show(ErrorMessages.TitleRequired);
            return;
        }
        if (!description) {
            Toast.show(ErrorMessages.DescriptionRequired);
            return;
        }

        Remote.call(
            ApiService.sendReport({
                Title: title,
                Description: description,
                ParentId: location.state.parentId,
            }),
            (response: IApiResultData<boolean>) => {
                if (response.data) {
                    Toast.show(response.message);
                    clearInputs();
                    timer = setTimeout(() => navigate(-1), 1500);
                } else {
                    Toast.show(response.message);
                }
            },
            (onProgress: boolean) => {
                setIsLoading(onProgress);
            },
            (error: string) => {
                Toast.show(error);
            }
        );
    };

    return (
        <AppContainer isLoadingSpinner={isLoading}>
            <AppToolbar title={GlobalConstants.AppName} onClickBack={handleGoBack}/>

            <Container className={classes.container}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={0}
                >
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" color="initial">
                            {AppMessages.SendIdeaPageDescription}
                        </Typography>
                    </Grid>

                    <Grid style={{marginTop: 20}} item xs={12} md={12}>
                        <TextField
                            id="title"
                            variant="outlined"
                            className={classes.input}
                            placeholder={AppMessages.Title}
                            dir="rtl"
                            size="small"
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </Grid>

                    <Grid style={{marginTop: 20}} item xs={12} md={12}>
                        <TextField
                            id="description"
                            variant="outlined"
                            className={classes.input}
                            placeholder={AppMessages.DescriptionText}
                            dir="rtl"
                            size="small"
                            multiline
                            minRows={7}
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </Grid>

                    <Grid style={{marginTop: 20}} item xs={12} md={12}>
                        <Button
                            className={classes.btnSend}
                            type="button"
                            onClick={sendReport}
                            variant="contained"
                        >
                            {AppMessages.Send}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </AppContainer>
    );
};

export default SendReport;
