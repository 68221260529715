import {useNavigate} from "react-router-dom";
import Routing from "../../Routing/routing";
import AppContainer from "../core/AppContainer";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import useStyles from "./help.styles";
import {AppMessages} from "../../ApplicationMessages/AppMessages";
import {Box, Container, Grid} from "@material-ui/core";
import {useEffect, useState} from "react";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import {BaseSettingData} from "../../Models/AppModels/BaseSettingData";
import IApiResultData from "../../Models/IApiResultData";
import {Toast} from "../../Utilities/Toast";
import {GlobalStyle} from "../../Utilities/GlobalStyle";

const Help = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageData, setPageData] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        GetPageData();
    }, []);

    const GetPageData = () => {
        Remote.call(
            ApiService.getBaseSettingData(),
            (response: IApiResultData<BaseSettingData>) => {
                if (response.isSuccess && response.data) {
                    setPageData(response.data.helpGuideText);
                }
            },
            (onProgress: boolean) => {
                setIsLoading(onProgress);
            },
            (error: string) => {
                Toast.show(error);
            }
        );
    };

    const handleGoBack = () => {
        navigate(Routing.Main);
    };

    return (
        <AppContainer isLoadingSpinner={isLoading}>
            <AppToolbar
                title={AppMessages.Help}
                onClickBack={handleGoBack}
            />
            <Box className={classes.container}>
                <Grid container className={GlobalStyle().main}>
                    <div className={classes.content}>
                        <p dangerouslySetInnerHTML={{__html: pageData}}/>
                    </div>
                </Grid>
            </Box>
        </AppContainer>
    );
};

export default Help;
