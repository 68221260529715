import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../../Utilities/GlobalConstants";

export const useStylesGeneral = makeStyles((theme) => ({
  AppBar: {
    backgroundColor: GlobalConstants.PrimaryColor,
    justifyContent: "space-between",
    borderBottomRightRadius:GlobalConstants.headerRadius,
    borderBottomLeftRadius:GlobalConstants.headerRadius,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    fontSize: "14px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  leftTitle: {
    fontSize: "10px",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
  },
  menuButton: {
    marginLeft: -12,
  },
  sectionDesktop: {
    display: "flex",
  },
}));
