import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import { User } from "../../Models/AppModels/User";
import IApiResultData from "../../Models/IApiResultData";
import { UserManager } from "../../Utilities/UserManager";
import { Validation } from "../../Utilities/Validators/Validation";
import AppButton from "../core/App-Button/App-Button";
import AppContainer from "../core/AppContainer";
import AppTextField from "../core/TextField/App-Text_field";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import useStyles from "./profile.styles";
import Routing from "./../../Routing/routing";
import AlertDialog from "../core/dialog/AlertDialog";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { Toast } from "../../Utilities/Toast";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AppGridItem from "../core/Grid/App-Grid-Item";
import AppDatePicker from "../core/datepicker/App-Date-Picker";
import AppSelect from "../core/Select/App-Select";
import PensionFundTypeList from "../../Utilities/Constant/PensionFundTypeList";
import EmploymentTypeList from "../../Utilities/Constant/EmploymentTypeList";
import JobTypeList from "../../Utilities/Constant/JobTypeList";
import AppSelectGroup, {
  SelectGroupModel,
} from "../core/Select/App-Select-Group";
import { JobTypeEnum } from "../../Models/Enums/JobTypeEnum";

const Profile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [nationalCode, setNationalCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [family, setFamily] = useState<string>("");
  const [legalCode, setLegalCode] = useState<string>("");
  const [fatherName, setFatherName] = useState<string>("");
  const [birthCertificateId, setBirthCertificateId] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string | null>(null);
  const [phoneNumberHome, setPhoneNumberHome] = useState<string>("");
  const [phoneNumberWork, setPhoneNumberWork] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [jobLocationList, setJobLocationList] = useState<SelectGroupModel[]>(
    []
  );

  const [pensionFundType, setPensionFundType] = useState<number>(0);
  const [employmentType, setEmploymentType] = useState<number>(0);
  const [jobType, setJobType] = useState<number>(0);
  const [jobLocation, setJobLocation] = useState<number>(0);
  //show
  const [showJobLocation, setShowJobLocation] = useState<boolean>(false);

  useEffect(() => {
    RequestGetUserInfo();
    callGetJobLocationList();
  }, []);

  const callGetJobLocationList = () => {
    Remote.call(
      ApiService.getJobLocationList(),
      (response: IApiResultData<SelectGroupModel[]>) => {
        if (response.data) {
          setJobLocationList(response.data);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const phoneNumberChange = (event: any): void => {
    setPhoneNumber(event.target.value);
  };
  const nationalCodeChange = (event: any): void => {
    setNationalCode(event.target.value);
  };
  const nameChange = (event: any): void => {
    setName(event.target.value);
  };
  const familyChange = (event: any): void => {
    setFamily(event.target.value);
  };
  const legalCodeChange = (event: any): void => {
    setLegalCode(event.target.value);
  };
  const fatherNameChange = (event: any): void => {
    setFatherName(event.target.value);
  };
  const birthCertificateIdChange = (event: any): void => {
    setBirthCertificateId(event.target.value);
  };
  const birthDateChange = (date: string): void => {
    setBirthDate(date);
  };
  const phoneNumberHomeChange = (event: any): void => {
    setPhoneNumberHome(event.target.value);
  };
  const phoneNumberWorkChange = (event: any): void => {
    setPhoneNumberWork(event.target.value);
  };
  const emailChange = (event: any): void => {
    setEmail(event.target.value);
  };

  const accountNumberChange = (event: any): void => {
    setAccountNumber(event.target.value);
  };

  const pensionFundTypeChange = (event: any): void => {
    setPensionFundType(event.target.value);
  };
  const employmentTypeChange = (event: any): void => {
    setEmploymentType(event.target.value);
  };
  const jobTypeChange = (event: any): void => {
    setJobType(event.target.value);
    if (event.target.value === 1) {
      setShowJobLocation(true);
    } else {
      setShowJobLocation(false);
      setJobLocation(0);
    }
  };
  const jobLocationChange = (event: any): void => {
    setJobLocation(event.target.value);
  };

  const RequestGetUserInfo = () => {
    Remote.call(
      ApiService.getUserInfo(),
      (response: IApiResultData<User>) => {
        if (response.isSuccess && response.data) {
          setUserResponse(response.data);
        } else {
          gotoMain();
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
        gotoMain();
      }
    );
  };

  const gotoMain = () => {
    navigate(Routing.Main);
  };

  const setUserResponse = (user?: User) => {
    if (user != null) {
      setPhoneNumber(user.mobile ? user.mobile : "");
      setNationalCode(user.nationalCode ? user.nationalCode : "");
      setName(user.name ? user.name : "");
      setFamily(user.family ? user.family : "");
      setLegalCode(user.legalCode ? user.legalCode : "");
      setFatherName(user.fatherName ? user.fatherName : "");
      setBirthCertificateId(
        user.birthCertificateId ? user.birthCertificateId : ""
      );
      setBirthDate(user.birthDate ? user.birthDate : "");
      setPhoneNumberHome(user.homePhoneNumber ? user.homePhoneNumber : "");
      setPhoneNumberWork(user.workPhoneNumber ? user.workPhoneNumber : "");
      setEmail(user.email ? user.email : "");
      setAccountNumber(user.accountNumber ? user.accountNumber : "");

      setPensionFundType(user.pensionFundType ? user.pensionFundType : 0);
      setEmploymentType(user.employmentType ? user.employmentType : 0);
      setJobType(user.jobType ? user.jobType : 0);

      if (user.jobType === JobTypeEnum.Employed) {
        setShowJobLocation(true);
      }

      setJobLocation(user.jobLocation ? user.jobLocation : 0);
    }
  };

  const onClickSubmit = () => {
    let user = new User();
    if (!Validation.isValid(name)) {
      toast("نام را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(family)) {
      toast("نام خانوادگی را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(legalCode)) {
      toast("کد حقوقی را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(fatherName)) {
      toast("نام پدر را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(birthCertificateId)) {
      toast("شماره شناسنامه را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(birthDate ? birthDate : "")) {
      toast("تاریخ تولد را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(phoneNumberHome)) {
      toast("شماره تلفن خانه را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(phoneNumberWork)) {
      toast("شماره محل کار را صحیح وارد نمایید");
      return;
    }
    if (!Validation.isValid(email)) {
      toast("ایمیل را صحیح وارد نمایید");
      return;
    }

    if (!Validation.isValid(accountNumber)) {
      toast("شماره حساب را صحیح وارد نمایید");
      return;
    }

    if (pensionFundType === 0) {
      toast("نوع صندوق بازنشستگی را انتخاب نمایید");
      return;
    }
    if (employmentType === 0) {
      toast("نوع استخدام را انتخاب نمایید");
      return;
    }
    if (jobType === 0) {
      toast("نوع شغل را انتخاب نمایید");
      return;
    }
    user.mobile = phoneNumber;
    user.name = name;
    user.family = family;
    user.nationalCode = nationalCode;
    user.legalCode = legalCode;
    user.fatherName = fatherName;
    user.birthCertificateId = birthCertificateId;
    user.birthDate = birthDate ? birthDate : "";
    user.homePhoneNumber = phoneNumberHome;
    user.workPhoneNumber = phoneNumberWork;
    user.email = email;
    user.accountNumber = accountNumber;

    const getPensionFundType = PensionFundTypeList.find((element) => {
      return element.id === pensionFundType;
    });
    user.pensionFundType = getPensionFundType ? getPensionFundType.id : 0;

    const getEmploymentType = EmploymentTypeList.find((element) => {
      return element.id === employmentType;
    });
    user.employmentType = getEmploymentType ? getEmploymentType.id : 0;

    const getJobType = JobTypeList.find((element) => {
      return element.id === jobType;
    });
    user.jobType = getJobType ? getJobType.id : 0;

    user.jobLocation = jobLocation;

    RequestEditProfile(user);
  };

  const RequestEditProfile = (user: User) => {
    Remote.call(
      ApiService.editProfile(user),
      (response: IApiResultData<User>) => {
        if (response.data) {
          UserManager.get().saveUser(response.data);
          toast(response.message);
          gotoMain();
        } else {
          toast(response.message);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        toast(error);
      }
    );
  };

  const toast = (msg: string) => {
    Toast.show(msg);
  };

  const onClickExitAccount = () => {
    setDialog(true);
  };

  const handleGoBack = () => {
    navigate(Routing.Main);
  };

  const dialogResponse = (res: any) => {
    if (res === true) {
      UserManager.get().deleteUserData();
      navigate(Routing.Splash);
    }
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AlertDialog
        message={`${AppMessages.ExitAccount} ؟`}
        open={dialog}
        onChange={setDialog}
        onResponse={dialogResponse}
      />
      <AppToolbar title={AppMessages.Account} onClickBack={handleGoBack} />
      <div className={classes.divHeader}>
        <img
          alt=""
          src="/Images/ic_user_icon.png"
          style={{ width: 70, height: 70, marginTop: 10 }}
        />
        <p className={classes.userName}>
          {name} {family}
        </p>
      </div>

      <Box style={{ width: "100%" }}>
        <Grid container className={GlobalStyle().main}>
          <AppGridItem all={12} className={classes.layoutExitAccount}>
            <Typography
              className={classes.exitAccount}
              onClick={onClickExitAccount}
            >
              {AppMessages.ExitAccount}
            </Typography>
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="mobile"
              value={phoneNumber}
              disable={true}
              onChange={phoneNumberChange}
              inputProps={{
                maxLength: 11,
              }}
              label={AppMessages.phoneNumber}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="nationalCode"
              value={nationalCode}
              disable={true}
              onChange={nationalCodeChange}
              inputProps={{
                maxLength: 10,
              }}
              label={AppMessages.NationalCode}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="name"
              value={name}
              onChange={nameChange}
              label={AppMessages.Name}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="family"
              value={family}
              onChange={familyChange}
              label={AppMessages.Family}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="legalCode"
              value={legalCode}
              onChange={legalCodeChange}
              inputProps={{
                maxLength: 15,
              }}
              label={AppMessages.LegalCode}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="fatherName"
              value={fatherName}
              onChange={fatherNameChange}
              label={AppMessages.FatherName}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="birthCertificateId"
              value={birthCertificateId}
              onChange={birthCertificateIdChange}
              label={AppMessages.IdCode}
            />
          </AppGridItem>
          <AppGridItem>
            <AppDatePicker
              id="birthDate"
              value={birthDate}
              onChange={birthDateChange}
              label={AppMessages.BirthDate}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="phoneNumberHome"
              value={phoneNumberHome}
              onChange={phoneNumberHomeChange}
              label={AppMessages.PhoneNumberHome}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="phoneNumberWork"
              value={phoneNumberWork}
              onChange={phoneNumberWorkChange}
              label={AppMessages.PhoneNumberWork}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="accountNumber"
              value={accountNumber}
              onChange={accountNumberChange}
              label={AppMessages.AccountNumber}
            />
          </AppGridItem>
          <AppGridItem>
            <AppTextField
              id="email"
              value={email}
              onChange={emailChange}
              label={AppMessages.Email}
            />
          </AppGridItem>
          <AppGridItem>
            <AppSelect
              id="pensionFundType"
              label={AppMessages.pensionFundType}
              defaultValue={pensionFundType}
              data={PensionFundTypeList}
              onChange={pensionFundTypeChange}
            />
          </AppGridItem>
          <AppGridItem>
            <AppSelect
              id="employmentType"
              label={AppMessages.employmentType}
              defaultValue={employmentType}
              data={EmploymentTypeList}
              onChange={employmentTypeChange}
            />
          </AppGridItem>
          <AppGridItem>
            <AppSelect
              id="jobType"
              label={AppMessages.jobType}
              defaultValue={jobType}
              data={JobTypeList}
              onChange={jobTypeChange}
            />
          </AppGridItem>
          <AppGridItem>
            <AppSelectGroup
              id="jobLocation"
              onChange={jobLocationChange}
              hidden={!showJobLocation}
              defaultValue={jobLocation}
              data={jobLocationList}
              label={AppMessages.JobLocation}
            />
          </AppGridItem>
          <AppGridItem all={12} className={classes.divBtn}>
            <AppButton
              type="button"
              className={GlobalStyle().btnAuth}
              style={{ marginTop: "30px", marginBottom: "30px" }}
              label={AppMessages.accept}
              onClick={onClickSubmit}
            />
          </AppGridItem>
        </Grid>
      </Box>
    </AppContainer>
  );
};

export default Profile;
