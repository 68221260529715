import PaymentResult from "../components/PaymentResult/PaymentResult.component";

const Routing = {
  Splash: "/",
  Login: "/login",
  Register: "/register",

  Main: "/main",
  Home: "home",
  Chat: "chat",
  SendIdea: "sendIdea",

  ChatItem: "/chatItem",
  SendReport: "/sendReport",
  Profile: "/profile",
  Player: "/Player",
  PdfView: "/Pdf",
  Help: "/help",
  About: "/about",
  RequestLoan: "/RequestLoan",
  GuarantorRequestLoan: "/GuarantorRequestLoan",

  MyGuarantor: "/MyGuarantor",
  Guarantee: "/Guarantee",
  Transactions: "/Transactions",
  PaymentResult: "/PaymentResult",

  ListRequestLoan: "/ListRequestLoan",
  PaymentInstallment: "/PaymentInstallment",
};

export default Routing;
