import "core-js/es/map";
import "core-js/es/set";
import "core-js/es/array/find";
import "core-js/es/array/includes";
import "core-js/es/number/is-nan";
import App from "./App";
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(<App />);
serviceWorkerRegistration.register();
