import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { PensionFundType } from "../../Models/Enums/PensionFundType";

const PensionFundTypeList = [
  { id: 0, value: AppMessages.Choose },
  { id: PensionFundType.SocialSecurity, value: "تامین اجتماعی" },
  { id: PensionFundType.RetirementOrganization, value: "سازمان بازنشستگی" },
];

export default PensionFundTypeList;
