export const convertNonEnglishNumbersToEnglish = (text: string): string => {
  if (text) {
    const digitsArray = [
      { Key: "۰", Value: "0" },
      { Key: "۱", Value: "1" },
      { Key: "۲", Value: "2" },
      { Key: "۳", Value: "3" },
      { Key: "۴", Value: "4" },
      { Key: "۵", Value: "5" },
      { Key: "۶", Value: "6" },
      { Key: "۷", Value: "7" },
      { Key: "۸", Value: "8" },
      { Key: "۹", Value: "9" },
      { Key: "٠", Value: "0" },
      { Key: "١", Value: "1" },
      { Key: "٢", Value: "2" },
      { Key: "٣", Value: "3" },
      { Key: "٤", Value: "4" },
      { Key: "٥", Value: "5" },
      { Key: "٦", Value: "6" },
      { Key: "٧", Value: "7" },
      { Key: "٨", Value: "8" },
      { Key: "٩", Value: "9" },
    ];

    let englishOutput: string = "";

    for (var i = 0; i < text.length; i++) {
      if (digitsArray.find((x) => x.Key === text[i])) {
        englishOutput += digitsArray.find((x) => x.Key === text[i])?.Value;
      } else {
        englishOutput += text[i];
      }
    }

    return englishOutput;
  }

  return "";
};
