import api from "./api";
import ConfirmCode from "../Models/AppModels/ConfirmCode";
import SendIdeaAndSuggestion from "../Models/AppModels/SendIdeaAndSuggestion";
import Url from "./RequestUrls";
import { User } from "../Models/AppModels/User";
import { CreateNewTicketMessage } from "../Models/AppModels/CreateNewTicketMessage";
import SendReport from "../Models/AppModels/SendReport";
import { UpdateFcmToken } from "../Models/AppModels/UpdateFcmToken";
import { convertNonEnglishNumbersToEnglish } from "../Utilities/Helpers/StringHelpers";
import SendPaymentInstallment from "../Models/AppModels/SendPaymentInstallment";
class ApiService {
  pageSize = 20;
  checkRegister(mobile: string, nationalCode: string) {
    return api.get(
      `${Url.CheckRegister}?mobile=${convertNonEnglishNumbersToEnglish(
        mobile
      )}&nationalCode=${convertNonEnglishNumbersToEnglish(nationalCode)}`
    );
  }

  register(data: User) {
    return api.post(Url.Register, data);
  }

  confirmCode(data: ConfirmCode) {
    data.ConfirmCode = convertNonEnglishNumbersToEnglish(data.ConfirmCode);
    data.Mobile = convertNonEnglishNumbersToEnglish(data.Mobile);
    return api.post(Url.ConfirmCode, data);
  }

  resendConfirmCode(mobile: string) {
    return api.post(Url.ResendConfirmCode, {
      Mobile: convertNonEnglishNumbersToEnglish(mobile),
    });
  }

  sendIdeaAndSuggestion(data: SendIdeaAndSuggestion) {
    return api.post(Url.SendIdeaAndSuggestion, data);
  }

  getTicketItems() {
    return api.get(Url.GetTicketItems);
  }

  getTicketMessages(ticketItemId: number, page: number, pageSize: number) {
    return api.get(
      `${Url.GetTicketMessages}?Id=${ticketItemId}&Page=${page}&PageSize=${pageSize}`
    );
  }

  createNewTicketMessage(data: CreateNewTicketMessage) {
    return api.post(Url.CreateNewTicketMessage, data);
  }

  getUserLoan() {
    return api.get(Url.getUserLoan);
  }

  getMembershipFeeInfo() {
    return api.get(Url.getMembershipFeeInfo);
  }

  getUserCurrentLoanRequest() {
    return api.get(Url.getUserCurrentLoanRequest);
  }

  getUserReceivableLoanInfo() {
    return api.get(Url.getUserReceivableLoanInfo);
  }

  getDataById(dataId: number) {
    return api.get(Url.GetDataById + `?dataId=${dataId}`);
  }

  editProfile(user: User) {
    return api.post(Url.EditProfile, user);
  }

  sendReport(model: SendReport) {
    return api.post(Url.SendReport, model);
  }

  updateFcmToken(model: UpdateFcmToken) {
    return api.post(Url.UpdateFcmToken, model);
  }

  logError(message: string, name: string, stack: any, componentStack: string) {
    return api.post(Url.LogError, { message, name, stack, componentStack });
  }

  getUserFcmTopic() {
    return api.get(Url.GetUserFcmTopic);
  }

  getJobLocationList() {
    return api.get(Url.GetJobLocationList);
  }

  setFcmTopicSubscription(fcmToken: string, topic: string) {
    return api.post(Url.SetFcmTopicSubscription, { fcmToken, topic });
  }

  ///////////////////////////

  getUserInfo() {
    return api.get(Url.GetUserInfo);
  }

  getBaseSettingData() {
    return api.get(Url.GetBaseSettingData);
  }

  createRequestLoanInitial() {
    return api.post(Url.createRequestLoanInitial);
  }

  requestMaxLoan(formData: FormData) {
    return api.post(Url.requestMaxLoan, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  addGuarantor(nationalCode: string) {
    const data = {
      nationalCode: convertNonEnglishNumbersToEnglish(nationalCode),
    };
    return api.post(Url.addGuarantor, data);
  }

  getUserGuaranteeList() {
    return api.get(Url.getUserGuaranteeList);
  }

  getUserLoanRequestGuarantorsList() {
    return api.get(Url.getUserLoanRequestGuarantorsList);
  }

  setGuarantorStatus(id: number, status: number) {
    const data = { loanRequestGuarantorId: id, status: status };
    return api.post(Url.setGuarantorStatus, data);
  }

  getTransactions(page: number) {
    return api.get(
      `${Url.getTransactions}?Page=${page}&PageSize=${this.pageSize}`
    );
  }

  getListRequestLoan(page: number) {
    return api.get(
      `${Url.getLoanRequestsList}?Page=${page}&PageSize=${this.pageSize}`
    );
  }

  submitCurrentLoanInstallmentReceiptByUser(data: SendPaymentInstallment) {
    return api.post(
      Url.submitCurrentLoanInstallmentReceiptByUser,
      data.formData
    );
  }
}

const apiService = new ApiService();

export default apiService;
