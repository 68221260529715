import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  typoGrid: {
    marginTop: "5%",
  },
  otpRemainingTimeGrid: {
    marginTop: "2%",
  },
  txtFieldGrid: {
    marginTop: "1%",
  },

  remainingSeconds: {
    color: "#ff8f00",
  },
  TypoRemaining: {
    display: "inline-flex",
  },
  divTextField:{
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
}));

export default useStyles;
