export class User {
  id: string = "";
  mobile: string = "";
  name: string = "";
  family: string = "";
  image: string = "";
  //كد ملی
  nationalCode: string = "";
  //كد حقوقي
  legalCode: string = "";
  //نام پدر
  fatherName: string = "";
  //شماره شناسنامه
  birthCertificateId: string = "";
  //تاریخ تولد
  birthDate: string = "";
  //شماره  تلفن منزل
  homePhoneNumber: string = "";
  //شماره تلفن محل کار
  workPhoneNumber: string = "";
  //ایمیل
  email: string = "";
  accountNumber: string = "";
  //نوع صندوق بازنشستگی
  pensionFundType: number = 0;
  //نوع استخدام
  employmentType: number = 0;
  //نوع شغل
  jobType: number = 0;
  //محل شغل
  jobLocation: number = 0;

  static newUser(mobile: string, name: string, family: string) {
    const user = new User();
    user.mobile = mobile;
    user.name = name;
    user.family = family;
    return user;
  }

  getFullName = (): string => {
    return `${this.name} ${this.family}`;
  };
}
