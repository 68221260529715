export enum LoanRequestGuarantorAcceptStatusType {
    //نامشخص
    Unknown = 0,
    //تایید
    Accepted = 1,
    //عدم تایید
    Denied = 2,
}

export const LoanRequestGuarantorAcceptStatusTypeLabel =
    new Map<LoanRequestGuarantorAcceptStatusType, string>(
        [
            [LoanRequestGuarantorAcceptStatusType.Unknown, "نامشخص"],
            [LoanRequestGuarantorAcceptStatusType.Accepted, "تایید"],
            [LoanRequestGuarantorAcceptStatusType.Denied, "عدم تایید"],
        ]
    );

export const GetLoanRequestGuarantorAcceptStatusById = (id: number) => {
    if (id === LoanRequestGuarantorAcceptStatusType.Unknown) return "نامشخص"
    else if (id === LoanRequestGuarantorAcceptStatusType.Accepted) return "تایید"
    else if (id === LoanRequestGuarantorAcceptStatusType.Denied) return "عدم تایید"
    else return "نامشخص"
}
