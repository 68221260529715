import {Typography} from "@mui/material";
import React from "react";

import {Box} from "@material-ui/core";
import {AppMessages} from "../../ApplicationMessages/AppMessages";

export const LayoutNoData = (props: {
    title?: string
    show?: boolean
}) => {
    return (
        <div hidden={props?.show ? props?.show : false}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                textAlign: "center",
                marginTop:"50px"
            }}>
                <Box width={{xs: 1, sm: 2, md: 1}}
                     sx={{
                         borderRadius: 30,
                         border: "1px #DEDEDEFF solid",
                         bgcolor: "#fff",
                     }}>
                    <br/>
                    <img src="/Images/no_data.jpg" alt="no_data" width="50%"/>
                    <br/>
                    <br/>
                    <Typography style={{fontSize:"16px"}} variant="h6" component="h2">
                        {props?.title ? props?.title : AppMessages.noData}
                    </Typography>
                    <br/>
                </Box>
            </Box>
        </div>
    );
};


