import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../Utilities/GlobalConstants";

const useStyles = makeStyles((theme) => ({
  container: {
   width: "100vw",
    height: "100vh",
  },
  centerItems: {
    alignItems: "center",
  },
  paymentBox: {
    alignItems: "center",
    textAlign: "center",
    marginTop: "30px",
    border: "2px solid #000",
    borderRadius:"10px",
    paddingTop:"30px",
    paddingBottom:"30px",
    paddingRight:"60px",
    paddingLeft:"60px",
  },
  btnOutline: {
    boxShadow: "none",
    backgroundColor: GlobalConstants.OnPrimaryColor,
    color: GlobalConstants.TextSecondaryColor,
    paddingTop: "8px",
    paddingBottom :"8px",
    paddingLeft:"25px",
    paddingRight:"25px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: GlobalConstants.OnSecondaryColor,
      borderRadius: "5px",
      boxShadow: "none",
    },
  },
}));
export default useStyles;
