import "./LoadingSpinner.css";
import {CircularProgress, createTheme} from "@material-ui/core";
import {GlobalConstants} from "../../../Utilities/GlobalConstants";

const LoadingSpinner = () => {
    return (
        /* <div className="loading">Loading&#8230;</div>*/
        <div className="loading">
            <CircularProgress  style={{color:GlobalConstants.OnPrimaryColor}}/>
        </div>
    );
};


export default LoadingSpinner;
