import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../../Utilities/GlobalConstants";

export const useStylesGeneral = makeStyles((theme) => ({
  menuButton: {
    marginLeft: -12,
  },
  AppBar: {
    backgroundColor: GlobalConstants.PrimaryColor,
    justifyContent: "space-between",
    borderBottomRightRadius:GlobalConstants.headerRadius,
    borderBottomLeftRadius:GlobalConstants.headerRadius,
  },
  Title: {
    fontSize: "14px",
    marginLeft: "20px",
    marginRight: "20px",
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    borderRadius: "50%",
    height: "50px",
    padding: "5px",
    marginRight: "10px"
  },
}));
