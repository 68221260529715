import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import AppContainer from "../core/AppContainer";
import useStyles from "./sendIdea.styles";
import {Toast} from "../../Utilities/Toast";
import { ErrorMessages } from "../../ApplicationMessages/AppMessages";
import SendIdeaAndSuggestion from "../../Models/AppModels/SendIdeaAndSuggestion";
import IApiResultData from "../../Models/IApiResultData";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";

const SendIdea = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const clearInputs = () => {
    setTitle("");
    setDescription("");
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value: string = event.target.value;
    setTitle(value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value: string = event.target.value;
    setDescription(value);
  };

  const sendIdea = () => {
    if (!title) {
      Toast.show(ErrorMessages.TitleRequired);
      return;
    }
    if (!description) {
      Toast.show(ErrorMessages.DescriptionRequired);
      return;
    }
    const model: SendIdeaAndSuggestion = {
      Title: title,
      Description: description,
    };

    Remote.call(
      ApiService.sendIdeaAndSuggestion(model),
      (response: IApiResultData<boolean>) => {
        if (response.data) {
          Toast.show(response.message);
          clearInputs();
        } else {
          Toast.show(response.message);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <Container className={classes.container}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12} md={12}>
            <Typography
              style={{ textAlign: "center" }}
              variant="h6"
              color="initial"
            >
              {AppMessages.SendIdeaPageDescription}
            </Typography>
          </Grid>

          <Grid style={{ marginTop: 20 }} item xs={12} md={12}>
            <TextField
              id="title"
              variant="outlined"
              className={classes.input}
              placeholder={AppMessages.Title}
              dir="rtl"
              size="small"
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>

          <Grid style={{ marginTop: 20 }} item xs={12} md={12}>
            <TextField
              id="description"
              variant="outlined"
              className={classes.input}
              placeholder={AppMessages.DescriptionText}
              dir="rtl"
              size="small"
              multiline
              minRows={7}
              value={description}
              onChange={handleDescriptionChange}
            />
          </Grid>

          <Grid style={{ marginTop: 20 }} item xs={12} md={12}>
            <Button
              className={classes.btnSend}
              type="button"
              onClick={sendIdea}
              variant="contained"
            >
              {AppMessages.Send}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </AppContainer>
  );
};

export default SendIdea;
