import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  div: {
    width: "100%",
  },
  divLabel: {
    width: "100%",
    paddingRight: "5px",
    paddingLeft: "5px",
    textAlign: "right",
    alignContent: "right"
  },
  label: {
    textAlign: "right",
    alignContent: "right",
  },
  view: {
    backgroundColor: "#ffffff",
  },
}));
export default useStyles;
