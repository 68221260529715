import {GlobalConstants} from "./GlobalConstants";
import {makeStyles} from "@material-ui/core";


export const GlobalStyle = makeStyles((theme) => ({
  btnPrimary: {
    backgroundColor: GlobalConstants.OnPrimaryColor,
    color: GlobalConstants.TextSecondaryColor,
    padding: "8px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40vw",
    },
    "&:hover": {
      backgroundColor: GlobalConstants.OnSecondaryColor,
      borderRadius: "5px",
    },
  },

  btnOutline: {
    boxShadow:"none",
    borderColor:GlobalConstants.PrimaryColor,
    padding: "8px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40vw",
    },
    "&:hover": {
      backgroundColor: GlobalConstants.OnSecondaryColor,
      borderRadius: "5px",
      boxShadow:"none",
    },
  },

  btnAuth:  {
    backgroundColor: GlobalConstants.OnPrimaryColor,
    color: GlobalConstants.TextSecondaryColor,
    padding: "8px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
    "&:hover": {
      backgroundColor: GlobalConstants.OnSecondaryColor,
      borderRadius: "5px",
    },
  },
  main: {
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingTop: "10px",
    paddingBottom:"100px",
    alignContent:"center",
  },

  select: {
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40vw",
    },
  },

  labelHeader: {
    fontSize: "20px!important",
    fontWeight: "bold!important"as any,
    textAlign: "right",
    padding : "8px",
    color: GlobalConstants.TextPrimaryColor
  },
  labelTitle: {
    fontSize: "16px!important",
    fontWeight: "bold!important"as any,
    textAlign: "right",
    padding : "4px",
    color: GlobalConstants.TextPrimaryColor
  },
  labelContent: {
    fontSize: "14px!important",
    fontWeight: "normal!important"as any,
    textAlign: "right",
    padding : "2px",
    color: GlobalConstants.TextPrimaryColor
  },
  labelDescription: {
    fontSize: "12px!important",
    fontWeight: "normal!important"as any,
    textAlign: "right",
    padding : "2px",
    color: GlobalConstants.TextPrimaryColor
  },
}));
