import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  messageRow: {
    display: "flex",
  },
  messageRowRight: {
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: "msi!important",
  },
  messageBlue: {
    position: "relative",
    marginLeft: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#f1fdf7",
    maxWidth: "90vw",
    wordBreak: "break-word!important" as any,
    width: "max-content",
    border: "1px solid #c3fde1",
    borderTopRightRadius: "2px",
    borderTopLeftRadius: "14px",
    borderBottomRightRadius: "14px",
    borderBottomLeftRadius: "14px",
  },
  messageGray: {
    position: "relative",
    marginRight: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#f8f8f8",
    maxWidth: "90vw",
    width: "max-content",
    border: "1px solid #e8ebe9",
    borderTopRightRadius: "14px",
    borderTopLeftRadius: "2px",
    borderBottomRightRadius: "14px",
    borderBottomLeftRadius: "14px",
  },
  messageContent: {
    fontSize: "12px",
    padding: 0,
    margin: 0,
    fontWeight: "bold",
  },
  messageTimeStampRight: {
    fontSize: "10px",
    marginTop: "10px",
    bottom: "-5px",
    right: "5px",
    direction: "ltr",
  },
  seenElementGreen: {
    width: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "16px",
    },
  },
}));

export const MessageRight = (props: {
  message: string;
  timestamp: string;
  seen: boolean;
}) => {
  const message = props.message ? props.message : "";
  const timestamp = props.timestamp ? props.timestamp : "";
  const classes = useStyles();

  const seenElement = props.seen ? (
    <img
      src="/Images/double-tick-green.png"
      className={classes.seenElementGreen}
      alt=""
    />
  ) : (
    <img src="/Images/double-tick-gray.png" alt="" />
  );

  return (
    <>
      <div className={classes.messageRow}>
        <div className={classes.messageBlue}>
          <div>
            <p className={classes.messageContent}>{message}</p>
          </div>
          <div className={classes.messageTimeStampRight}>
            {timestamp}
            &nbsp;
            {seenElement}
          </div>
        </div>
      </div>
    </>
  );
};

export const MessageLeft = (props: { message: string; timestamp: string }) => {
  const classes = useStyles();
  const message = props.message ? props.message : "";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageGray}>
        <p className={classes.messageContent}>{message}</p>
        <div className={classes.messageTimeStampRight}>{timestamp}</div>
      </div>
    </div>
  );
};
