import * as React from "react";
import { AppMessages } from "../../../ApplicationMessages/AppMessages";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { HomeRounded, TextsmsRounded } from "@mui/icons-material";

import {
  useStyles,
  useStylesGeneral,
} from "./App-Bottom-Navigation-MUI.styles";

const AppBottomNavigation = (props: {
  id?: string;
  value?: number;
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
}) => {
  const generalClasses = useStylesGeneral();
  const bottomNavigationActionClasses = useStyles();

  return (
    <BottomNavigation
      id={props.id ? props.id : "AppBottomNavigation"}
      value={props.value}
      className={generalClasses.BottomNavigation}
      onChange={(event, newValue) =>
        props.onChange ? props.onChange(event, newValue) : undefined
      }
      showLabels
    >
      <BottomNavigationAction
        classes={bottomNavigationActionClasses}
        label={AppMessages.Home}
        icon={<HomeRounded />}
      />

      <BottomNavigationAction
        classes={bottomNavigationActionClasses}
        label={AppMessages.CommunicationBox}
        icon={<TextsmsRounded />}
      />
    </BottomNavigation>
  );
};

export default AppBottomNavigation;
