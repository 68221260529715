import React, { useEffect, useState } from "react";

import {
  Avatar,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import useStyles from "./chatList.styles";

import TicketItem from "../../Models/AppModels/TicketItem";
import AppContainer from "../core/AppContainer";
import IApiResultData from "../../Models/IApiResultData";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import { useNavigate } from "react-router-dom";
import Routing from "./../../Routing/routing";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { sessionStorageHelper } from "../../Utilities/Helpers/sessionStorageHelper";
import { Toast } from "../../Utilities/Toast";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import {LayoutNoData} from "../core/LayoutNoData";

const ChatList = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [ticketItems, setTicketItems] = useState<TicketItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetTicketItems();
  }, []);

  const GetTicketItems = () => {
    Remote.call(
      ApiService.getTicketItems(),
      (response: IApiResultData<TicketItem[]>) => {
        if (response.data) {
          setTicketItems(response.data);
        }
      },
      (onProgress: boolean) => {
        setIsLoading(onProgress);
      },
      (error: string) => {
        Toast.show(error);
      }
    );
  };

  const showChatItem = (ticketItem: TicketItem) => {
    navigate(Routing.ChatItem, {
      replace: true,
      state: {
        id: ticketItem.id,
        icon: ticketItem.icon,
        title: ticketItem.title,
      },
    });
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <Container className={classes.container}>
        <Grid container className={GlobalStyle().main}>
          <LayoutNoData show={ticketItems.length !== 0} />
          <AppGridItem all={12} hidden={ticketItems.length === 0} style={{ padding: "0px" }}>
            <List>
              {ticketItems.map((ticketItem) => (
                <ListItem
                  onClick={() => showChatItem(ticketItem)}
                  className={classes.ListItemStyles}
                  key={ticketItem.id}
                  button
                >
                  <Avatar
                    alt={ticketItem.title}
                    src={ticketItem.icon}
                    style={{
                      width: "50px",
                      height: "50px",
                      border: `1.5px solid ${GlobalConstants.PrimaryColor}`,
                    }}
                  />

                  <ListItemText
                    primary={ticketItem.title}
                    secondary={ticketItem.description}
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  />

                  {ticketItem.newMessageCount > 0 ? (
                    <div className={classes.badgeStyles}>
                      {ticketItem.newMessageCount}
                      &nbsp;
                      {AppMessages.NewMessage}
                    </div>
                  ) : null}
                </ListItem>
              ))}
            </List>
          </AppGridItem>
        </Grid>
      </Container>
    </AppContainer>
  );
};

export default ChatList;
