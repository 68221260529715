import * as React from "react";
import {TextField} from "@material-ui/core";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";
import {Typography} from "@mui/material";
import useStyles from "./App-Text_field.styles";

const AppTextField = (props: {
    id?: string;
    label?: string;
    style?: React.CSSProperties;
    dir?: string;
    placeholder?: string;
    disable?: boolean;
    hidden?: boolean;
    inputProps?: StandardInputProps["inputProps"];
    type?: React.InputHTMLAttributes<unknown>["type"];
    className?: string;
    value?: string;
    required?: boolean;
    multiline?: boolean;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}) => {
    const classes = useStyles();
    if (props.hidden) return <div/>;
    return (
        <div className={props.className}>
            <div className={classes.div}>
                <div className={classes.divLabel}>
                    <Typography className={classes.label}>{props.label}</Typography>
                </div>
                <div>
                    <TextField
                        fullWidth={true}
                        required
                        style={props.style}
                        className={classes.view}
                        color={'primary'}
                        type={props.type ? props.type : "text"}
                        name={props.id}
                        inputProps={props.inputProps}
                        placeholder={props.placeholder}
                        dir={props.dir ? props.dir : "rtl"}
                        variant="outlined"
                        value={props.value}
                        onChange={props.onChange}
                        disabled={props.disable}
                        multiline={props.multiline}
                    />
                </div>
            </div>
        </div>
    );
};

export default AppTextField;
