import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../Utilities/GlobalConstants";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "100vh",
    width: "100%",
    textAlign: "center",
  },
  pdfView: {
    width: "100%",
  },
  divDownload: {
    width: "100%",
    padding: "2px",
  },
  downloadLink: {
    color: GlobalConstants.PrimaryColor,
  },
}));
export default useStyles;
