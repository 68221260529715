import { UserManager } from "../Utilities/UserManager";
import { baseUrl } from "./HttpConstants";
import Routing from "./../Routing/routing";
import Url from "./RequestUrls";
import axios from "axios";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    console.log(config.url);
    const token = UserManager.get().loadToken().token;
    if (token) {
      config.headers!.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/client/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post(Url.RefreshToken, {
            token: UserManager.get().loadToken().token,
            refreshToken: UserManager.get().loadToken().refreshToken,
          });

          UserManager.get().saveToken(rs.data.data);
          return instance(originalConfig);
        } catch (_error: any) {
          if (_error.response && _error.response.data.statusCode === 400) {
            UserManager.get().deleteUserData();
            window.location.href = Routing.Login;
          }
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
