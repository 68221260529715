import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    width: "100%",
    marginTop:"60px",
    paddingLeft:"10px",
    paddingRight:"10px",
    alignItems: "center",
    justifyContent: "center",
  },
  divMain: {
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "83vh",
    },
    [theme.breakpoints.up("sm")]: {
      height: "84vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "86vh",
    },
  },
  messagesBody: {
    padding: "1%",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    scrollbarColor: "none",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    justifyContent: "end",
    display: "flex",
    flexDirection: "column-reverse",
  },
  wrapText: {
    width: "100%",
  },
  wrapForm: {
    direction: "ltr",
    display: "flex",
    justifyContent: "center",
    width: "95%",
    paddingTop:"5px",
    margin: `${theme.spacing(0)} auto`,
  },
  notchedOutline: {
    borderRadius: "5px",
  },
}));
export default useStyles;
