import * as React from "react";
import AppLabel from "./App-Label";
import { GlobalStyle } from "../../../Utilities/GlobalStyle";

const AppLabelTitle = (props: {
  label?: string;
  style?: React.CSSProperties;
  dir?: string;
  hidden?: boolean;
  className?: string;
}) => {
  if (props.hidden) return <div />;
  let getClassName = GlobalStyle().labelTitle;
  if (props.className != null) getClassName = props.className;
  return (
    <AppLabel
      label={props.label}
      style={props.style}
      dir={props.dir}
      hidden={props.hidden}
      className={getClassName}
    />
  );
};

export default AppLabelTitle;
