import { AxiosResponse } from "axios";
import { ErrorMessages } from "../ApplicationMessages/AppMessages";
import IApiResultData from "../Models/IApiResultData";

class Remote {
  call<T, D>(
    request: Promise<AxiosResponse<IApiResultData<T>, D>>,
    onSuccess?: (value: IApiResultData<T>) => void,
    onProgress?: (value: boolean) => void,
    onError?: (value: string) => void
  ) {
    if (onProgress != null) {
      onProgress(true);
    }
    request
      .then((response) => {
        if (onProgress != null) {
          onProgress(false);
        }

        if (response.data) {
          console.log(response.data);
        }
        if (response.status === 200 && response.data.isSuccess) {
          if (onSuccess != null) {
            onSuccess(response.data);
          }
        } else if (response.status === 200 && !response.data.isSuccess) {
          if (onError != null) {
            onError(response.data.message);
          }
        } else {
          if (onError != null) {
            onError(ErrorMessages.ErrorConnection);
          }
        }
      })
      .catch(function (error) {
        let message = ErrorMessages.ErrorConnection;
        if (onProgress != null) {
          onProgress(false);
        }
        if (error.response) {
          //   console.dir(error.response.data);
          //   console.log(error.response.status);
          if (error.response.data) {
            message = error.response.data.message;
          }
          if (error.response.status === 404) {
            message = ErrorMessages.ErrorNotFound;
          }
        } else if (error.request) {
          //   console.dir(error.request);
          //   console.log(ErrorMessages.ErrorConnection);
        } else {
          //   console.log("Error in axios config", error.message);
        }
        if (onError != null) {
          onError(message);
        }
      });
  }
}

export default new Remote();
