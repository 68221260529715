import { makeStyles } from "@material-ui/core";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
const useStyles = makeStyles((theme) => ({
  divHeader: {
    textAlign: "center",
    marginTop: "40px",
    padding:"15px",
    backgroundColor: GlobalConstants.PrimaryColor,
    height: "150px",
    borderBottomLeftRadius: GlobalConstants.headerRadius,
    borderBottomRightRadius: GlobalConstants.headerRadius,
  },
  exitAccount: {
    width: "fit-content",
    color: GlobalConstants.PrimaryColor,
    fontSize : 14,
    fontWeight : "bold",
    cursor: "pointer",
  },
  layoutExitAccount: {
    direction:"ltr",
  },
  divBtn: {
    textAlign:"center",
  },
  userName: {
    color: "#fff",
    fontSize : 14,
    fontWeight : "bold"
  },
  userStatus: {
    color: "#fff",
    fontSize : 12,
  },
}));
export default useStyles;
