import React, { MouseEventHandler } from "react";
import { Button } from "@material-ui/core";
import { GlobalStyle } from "../../../Utilities/GlobalStyle";
import useStyles from "./App-Button.styles";

const AppButtonOutline = (props: {
  id?: string;
  label?: string;
  style?: React.CSSProperties;
  dir?: string;
  disable?: boolean;
  hidden?: boolean;
  className?: string;
  onClick?: MouseEventHandler<any> | undefined;
}) => {
  const classes = useStyles();
  if (props.hidden) return <div />;
  let btnClass = GlobalStyle().btnOutline;
  if (props.className != null) btnClass = props.className;
  return (
    <div hidden={props.hidden}>
      <Button
        disabled={props.disable}
        fullWidth={true}
        name={props.id}
        className={btnClass}
        style={props.style}
        type="button"
        onClick={props.onClick}
        variant="outlined"
      >
        <div className={classes.titleOutline}>{props.label}</div>
      </Button>
    </div>
  );
};
export default AppButtonOutline;
