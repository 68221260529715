import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
} from "react-device-detect";

export const DeviceInfoHelper = {
  getDevice() {
    return `Browser : ${browserName} (${fullBrowserVersion}) on OS ${osName} ${osVersion}`;
  },
};
