import { convertNonEnglishNumbersToEnglish } from "../Helpers/StringHelpers";

export const Validation = {
  isMobile(phoneNumber: string): boolean {
    if (phoneNumber) {
      phoneNumber = convertNonEnglishNumbersToEnglish(phoneNumber);
    }

    const reg = /^\d+$/;

    return (
      phoneNumber.length === 11 &&
      phoneNumber.startsWith("09") &&
      reg.test(phoneNumber)
    );
  },
  isNationalCode(nationalCode: string): boolean {
    if (nationalCode) {
      nationalCode = convertNonEnglishNumbersToEnglish(nationalCode);
    }

    const reg = /^\d+$/;
    return nationalCode.length === 10 && reg.test(nationalCode);
  },
  isValid(text: string, min: number = 3, max: number = 50): boolean {
    return text.length >= min && text.length <= max;
  },
};
