import React, { MouseEventHandler } from "react";
import { Button } from "@material-ui/core";
import { GlobalStyle } from "../../../Utilities/GlobalStyle";
import useStyles from "./App-Button.styles";

const AppButton = (props: {
  id?: string;
  label?: string;
  type: "button" | "submit" | "reset";
  style?: React.CSSProperties;
  dir?: string;
  disable?: boolean;
  hidden?: boolean;
  className?: string;
  onClick?: MouseEventHandler<any> | undefined;
}) => {
  const classes = useStyles();
  if (props.hidden) return <div />;

  let btnClass = GlobalStyle().btnPrimary;
  if (props.className != null) btnClass = props.className;
  return (
    <div hidden={props.hidden}>
      <Button
        fullWidth={true}
        name={props.id}
        className={btnClass}
        style={props.style}
        type={props.type}
        onClick={props.onClick}
        variant="contained"
      >
        <div className={classes.title}>{props.label}</div>
      </Button>
    </div>
  );
};
export default AppButton;
