import React, { UIEvent, useEffect, useState } from "react";

import { Container, Grid, List } from "@material-ui/core";
import AppContainer from "../core/AppContainer";
import IApiResultData from "../../Models/IApiResultData";
import ApiService from "../../httpService/ApiService";
import Remote from "../../httpService/Remote";
import { useNavigate } from "react-router-dom";
import Routing from "./../../Routing/routing";
import { Toast } from "../../Utilities/Toast";
import AppGridItem from "../core/Grid/App-Grid-Item";
import { GlobalStyle } from "../../Utilities/GlobalStyle";
import AppToolbar from "../core/Toolbar/App-Toolbar";
import { AppMessages } from "../../ApplicationMessages/AppMessages";
import { NumberHelper } from "../../Utilities/NumberHelper";
import { SwapHorizOutlined } from "@mui/icons-material";
import AppLabelTitle from "../core/Label/App-Label-Title";
import AppLabelContent from "../core/Label/App-Label-Content";
import { GlobalConstants } from "../../Utilities/GlobalConstants";
import { LayoutNoData } from "../core/LayoutNoData";
import useStyles from "./Transaction.styles";
import ResponseTransactionListDto from "../../Models/AppModels/Transaction/ResponseTransactionListDto";
import {
  getTransactionTypeLabel,
  TransactionType,
} from "../../Models/Enums/TransactionType";
import IPagedResultData from "../../Models/IPagedResultData";
import {
  TransactionStatus,
  TransactionStatusLabel,
} from "../../Models/Enums/TransactionStatus";

const Transactions = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState<ResponseTransactionListDto[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getUserTransactionsList(page);
  }, []);

  const getUserTransactionsList = (page: number) => {
    if (page === 1 || hasMoreData) {
      Remote.call(
        ApiService.getTransactions(page),
        (
          response: IApiResultData<IPagedResultData<ResponseTransactionListDto>>
        ) => {
          if (response.data) {
            const { items, totalPages } = response.data;
            setHasMoreData(totalPages > page);
            setDataList((prevDataList) =>
              page === 1 ? items : [...prevDataList, ...items]
            );
          }
        },
        (onProgress: boolean) => {
          setIsLoading(onProgress);
        },
        (error: string) => {
          Toast.show(error);
        }
      );
    }
  };

  const handleGoBack = () => {
    navigate(Routing.Main);
  };

  const handleScroll = (event: UIEvent<HTMLElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const diff = Math.abs(scrollHeight + scrollTop - clientHeight);
    if (diff >= 0 && diff <= 5 && hasMoreData) {
      setPage((prev) => prev + 1);
      getUserTransactionsList(page);
    }
  };

  return (
    <AppContainer isLoadingSpinner={isLoading}>
      <AppToolbar title={AppMessages.transactions} onClickBack={handleGoBack} />
      <Container className={classes.container}>
        <Grid container className={GlobalStyle().main}>
          <LayoutNoData show={dataList.length !== 0} />
          <AppGridItem
            all={12}
            hidden={dataList.length === 0}
            style={{ padding: "0px" }}
          >
            <List onScroll={handleScroll}>
              {dataList.map((data) => (
                <Grid
                  container
                  key={data.id}
                  style={{
                    border: "1.5px solid #00897B",
                    borderColor: GlobalConstants.PrimaryColor,
                    borderRadius: "10px",
                    marginTop: "14px",
                    alignItems: "center",
                  }}
                >
                  <AppGridItem all={3}>
                    <AppLabelTitle
                      label={`${data.description}`}
                      style={{ textAlign: "right" }}
                    />
                  </AppGridItem>
                  <AppGridItem all={3}>
                    <AppLabelContent
                      label={` مبلغ: ${NumberHelper.get(data.amount)}`}
                      style={{
                        color: "#969696",
                        textAlign: "right",
                      }}
                    />
                  </AppGridItem>
                  <AppGridItem all={3}>
                    <AppLabelContent
                      label={data.createdOn}
                      style={{
                        color: "#969696",
                        textAlign: "right",
                      }}
                    />
                  </AppGridItem>
                  <AppGridItem all={3}>
                    <AppLabelContent
                      label={getTransactionTypeLabel(data.transactionType)}
                      style={{
                        color:
                          data.transactionType.valueOf() ===
                          TransactionType.Settle
                            ? GlobalConstants.GreenColor
                            : GlobalConstants.RedColor,
                        textAlign: "center",
                      }}
                    />
                    <AppLabelContent
                      label={TransactionStatusLabel.get(data.transactionStatus)}
                      style={{
                        color:
                          data.transactionStatus === TransactionStatus.Success
                            ? GlobalConstants.GreenColor
                            : GlobalConstants.RedColor,
                        textAlign: "center",
                      }}
                    />
                  </AppGridItem>
                </Grid>
              ))}
            </List>
          </AppGridItem>
        </Grid>
      </Container>
    </AppContainer>
  );
};

export default Transactions;
